import React, { useState } from 'react';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';

import {
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import MobileStepper from '@material-ui/core/MobileStepper';
import { ArrowBack, ArrowForward } from '@material-ui/icons';

import { OnboardingScreenSlide } from './onboardingscreen-slide.model';

import IconButtonView from '../../../../atoms/Buttons/IconButton.view';
import { red } from '@material-ui/core/colors';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const useStyles = makeStyles((theme) => ({
  container: {
    height: '100%',
    width: '100%',
    borderTopRightRadius: 20,
    borderBottomRightRadius: 20,
  },
  swipeableViews: {
    height: '100%',
    width: '100%',
    display: 'flex',
    backgroundSize: 'cover',
    backgroundPosition: 'initial',
    // backgroundImage: (props: OnBoardingScreenViewProps & OnBoardingScreenViewStyleProps) => `url(${props.currentImage})`,
    color: theme.palette.background.default,
  },
  slideWrapper: {
    position: 'absolute',
    bottom: 80,
    width: '100%',
    padding: '20px 10% 20px',
    backgroundImage:
      'linear-gradient( rgb(212, 229, 248, 0.6) , rgb(212, 229, 248) )',
  },
  fixedContent: {
    position: 'relative',
    bottom: 80,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  arrow: {
    color: theme.palette.primary.dark,
    width: 22,
    height: 22,
  },
  mobileStepper: {
    marginBottom: theme.spacing(4),
    backgroundColor: 'transparent',
  },
  dot: {
    display: 'none',
    color: theme.palette.primary.dark,
    width: 12,
    height: 12,
  },
  buttons: { marginBottom: theme.spacing(15) },
}));

export declare interface OnBoardingScreenViewProps {
  slides: OnboardingScreenSlide[];

  onReachEnd: () => void;
}

export declare interface OnBoardingScreenViewStyleProps {
  currentImage: string;
}

const OnBoardingScreenView = ({
  slides,
  onReachEnd,
}: OnBoardingScreenViewProps) => {
  const theme = useTheme();
  const [activeStepIndex, setActiveStepIndex] = useState(0);
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  const classes = useStyles({ currentImage: slides[activeStepIndex].image });

  const maxSteps = slides.length;

  const handleNext = () => {
    if (activeStepIndex + 1 < maxSteps) {
      setActiveStepIndex(activeStepIndex + 1);
    } else onReachEnd();
  };

  const handleBack = () => {
    if (activeStepIndex - 1 > -1) {
      setActiveStepIndex(activeStepIndex - 1);
    }
  };

  return (
    <div className={classes.container}>
      <AutoPlaySwipeableViews
        enableMouseEvents={true}
        index={activeStepIndex}
        className={classes.container}
        containerStyle={{ height: '100%' }}
        onChangeIndex={(index) => setActiveStepIndex(index)}
        interval={7000}
      >
        {slides.map((item, idx) => (
          <div
            className={classes.swipeableViews}
            key={idx}
            style={{ backgroundImage: `url(${item.image})` }}
          >
            <div className={classes.slideWrapper}>
              <Typography
                variant={matches ? 'h2' : 'h5'}
                align="center"
                style={{ color: theme.palette.primary.main }}
              >
                {item.text}
              </Typography>
            </div>
          </div>
        ))}
      </AutoPlaySwipeableViews>

      <div className={classes.fixedContent}>
        <MobileStepper
          className={classes.mobileStepper}
          variant="dots"
          steps={maxSteps}
          activeStep={activeStepIndex}
          position="static"
          classes={{
            dot: classes.dot,
          }}
          backButton={
            <IconButtonView
              icon={() => <ArrowBack className={classes.arrow} />}
              onClick={handleBack}
            />
          }
          nextButton={
            <IconButtonView
              icon={() => <ArrowForward className={classes.arrow} />}
              onClick={handleNext}
            />
          }
        />
      </div>
    </div>
  );
};

export default OnBoardingScreenView;

import { Store, StoreConfig } from '@datorama/akita';

export interface UiState {
  filter: {
    visible: boolean;
  };
  capsule: {
    activityTabs: number;
  };
}

export const initialUiState: UiState = {
  filter: {
    visible: false,
  },
  capsule: {
    activityTabs: 1,
  },
};

@StoreConfig({
  name: 'ui',
})
export class UiStore extends Store<UiState> {
  constructor() {
    super(initialUiState);
  }

  displayFilter() {
    this.update((state) => ({ ...state, filter: { visible: true } }));
  }

  hideFilter() {
    this.update((state) => ({ ...state, filter: { visible: false } }));
  }
}

export const uiStore = new UiStore();

import { makeStyles } from '@material-ui/core/styles';
import { ThemeZideeUp } from '../../../theme';

export const useButtonStyles = makeStyles<ThemeZideeUp>((theme) => ({
  root: {
    minWidth: 158,
    paddingTop: 10,
    paddingBottom: 10,
    color: (props: any) => theme.palette.state[props.borderColor],
    borderColor: (props: any) => theme.palette.state[props.borderColor],
    borderRadius: 60,

    '&.MuiButton-outlined': {
      backgroundColor: 'white',
    },
    '&.Mui-disabled': {
      backgroundColor: '#cdcdcd !important',
      borderColor: '#cdcdcd !important',
      color: 'white !important',
    },
    '&.MuiButton-contained': {
      color: 'white',
      backgroundColor: (props: any) => theme.palette.state[props.borderColor],
    },
    '&.MuiButton-containedPrimary': {
      borderColor: theme.palette.primary.main,
    },
    '&.MuiButton-containedSecondary': {
      borderColor: theme.palette.secondary.main,
    },
    '&.MuiButton-outlinedSizeSmall': {
      paddingTop: 9,
      paddingBottom: 9,
      fontSize: '14px',

      [theme.breakpoints.down('sm')]: {
        padding: '7px 14px',
      },
    },
    '&.MuiButton-containedSizeSmall': {
      paddingTop: 9,
      paddingBottom: 9,
      fontSize: '14px',

      [theme.breakpoints.down('sm')]: {
        padding: '7px 14px',
      },
    },
    '&.MuiButton-text': {
      padding: '4px',
      borderRadius: 6,
    },

    [theme.breakpoints.down('sm')]: {
      minWidth: 0,
    },
  },
  secondaryButton: {
    padding: 12,
    fontSize: '14px',
    fontWeight: 400,
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.grey[400],
    borderColor: theme.palette.grey[400],
    borderRadius: 6,

    '&[bordercolor="action"]': {
      fontWeight: '700',
      color: 'white',
      backgroundColor: (props: any) => theme.palette.state[props.borderColor],
      borderColor: (props: any) => theme.palette.state[props.borderColor],
    },
  },
}));

import React from 'react';
import { initSentry } from './services/sentry';
import { AppHead } from '@components/molecules/AppHead/AppHead';
import { AppRouter } from '@routes/AppRouter/AppRouter';

initSentry();

const App = () => {
  return (
    <>
      <AppHead />
      <AppRouter />
    </>
  );
};

export default App;

import axios from 'axios';
import { debounceTime, from, iif, last, mergeMap, Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { CompanySearch } from './company-search.model';
import { Search } from './search.model';
import {
  initialSearchState,
  SearchState,
  SearchStore,
  searchStore,
} from './search.store';
import { Capsule } from '../capsule/capsule.model';
import { getCapsulesDirections } from '../capsule/capsule-validators/capsule-position.model';
import { Tag } from '../tag/tag.model';
import { SearchQuery, searchQuery as query } from './search.query';

export class SearchService {
  constructor(
    private searchStore: SearchStore,
    private searchQuery: SearchQuery
  ) {}

  getSearches(): Observable<CompanySearch[]> {
    return from(
      axios.get((window as any).env.REACT_APP_BACK_WEB_URL + '/search')
    ).pipe(
      tap((res) => {
        this.searchStore.set(res.data);
      }),
      map((res) => res.data)
    );
  }

  createSearch(search: Search): Observable<CompanySearch> {
    return from(
      axios.post((window as any).env.REACT_APP_BACK_WEB_URL + '/search', search)
    ).pipe(
      tap((res) => {
        this.searchStore.add(res.data);
      }),
      map((res) => res.data)
    );
  }

  debouncedCreateSearch(): Observable<string | Search> {
    const saveSearch = (search: Search) =>
      of(search).pipe(
        tap((search) => this.createSearch(search)),
        last()
      );

    return this.searchQuery
      .select((state) => state.search)
      .pipe(
        debounceTime(5000),
        mergeMap((search) =>
          iif(
            () => search.searchTerm !== '' && search.searchTerm.length > 5,
            saveSearch(search),
            of('nothing to do')
          )
        )
      );
  }

  setSearchStateFull(
    search: (currentState: SearchState) => Partial<Search>
  ): Observable<CompanySearch> {
    return this.searchStore.update((state) => ({
      ...state,
      search: {
        ...state.search,
        ...search(state),
      },
    }));
  }

  setSearch(search: Partial<Search>): void {
    const normalizedSearch = {
      ...search,
      searchTerm: search.searchTerm?.toLowerCase(),
    };
    this.searchStore.update((state) => ({
      ...state,
      search: {
        ...state.search,
        ...normalizedSearch,
      },
    }));
  }
  setSearchTerm(searchTerm: Search['searchTerm']): void {
    this.searchStore.update((state) => ({
      ...state,
      search: {
        ...state.search,
        searchTerm: searchTerm?.toLowerCase(),
      },
    }));
  }

  setSearchCheckBoxesAndButtons(key: string, value: any) {
    this.setSearchStateFull(({ search: oldSearch }) => {
      let newSearch = { ...oldSearch, [key]: !oldSearch[key] };
      // This handles switching ON/OFF switches which cannot be set to true at the same time.
      switch (key) {
        case 'showNews':
          if (!oldSearch.showNews) {
            newSearch.showRealised = false;
            newSearch.showNotResolved = false;
            newSearch.showPending = false;
            newSearch.showActivated = false;
            newSearch.showOld15Days = false;
            newSearch.showOld30Days = false;
            newSearch.showOld45Days = false;
          }
          break;
        case 'showActivated':
          if (!oldSearch.showActivated) {
            newSearch.showRealised = false;
            newSearch.showNotResolved = false;
            newSearch.showPending = false;
            newSearch.showNews = false;
          }
          break;
        case 'showRealised':
          if (!oldSearch.showRealised) {
            newSearch.showActivated = false;
            newSearch.showPending = false;
            newSearch.showNews = false;
            newSearch.showNotResolved = false;
          }
          break;
        case 'showNotResolved':
          if (!oldSearch.showNotResolved) {
            newSearch.showActivated = false;
            newSearch.showPending = false;
            newSearch.showNews = false;
            newSearch.showRealised = false;
          }
          break;
        case 'showPending':
          if (!oldSearch.showPending) {
            newSearch.showActivated = false;
            newSearch.showRealised = false;
            newSearch.showNotResolved = false;
            newSearch.showNews = false;
          }
          break;
        case 'orderByCreatedAtDesc':
          if (!oldSearch.orderByCreatedAtDesc) {
            newSearch.orderByCreatedAtAsc = false;
            newSearch.orderByCapsulePointsDesc = false;
          }
          break;
        case 'orderByCreatedAtAsc':
          if (!oldSearch.orderByCreatedAtAsc) {
            newSearch.orderByCreatedAtDesc = false;
            newSearch.orderByCapsulePointsDesc = false;
          }
          break;
        case 'orderByCapsulePointsDesc':
          if (!oldSearch.orderByCapsulePointsDesc) {
            newSearch.orderByCreatedAtAsc = false;
            newSearch.orderByCreatedAtDesc = false;
          }
          break;
        case 'constellations':
          newSearch.constellations = oldSearch.constellations;
          if (newSearch.constellations.length > 0) {
            let oldIndex = newSearch.constellations.findIndex(
              (constellation) => constellation === value
            );
            if (oldIndex >= 0) newSearch.constellations.splice(oldIndex, 1);
            if (oldIndex < 0) newSearch.constellations.push(value as number);
          } else {
            newSearch.constellations = [value as number];
          }

          break;
        case 'position':
          newSearch.position = oldSearch.position;
          if (newSearch.position.length > 0) {
            let oldIndex = newSearch.position.findIndex(
              (constellation) => constellation === value
            );
            if (oldIndex >= 0) newSearch.position.splice(oldIndex, 1);
            if (oldIndex < 0) newSearch.position.push(value as string);
          } else {
            newSearch.position = [value as string];
          }

          break;
        case 'tags':
          newSearch.categories.tags = oldSearch.categories.tags;
          if (newSearch.categories.tags.length > 0) {
            let oldIndex = newSearch.categories.tags.findIndex(
              (tag) => tag === value
            );
            if (oldIndex >= 0) newSearch.categories.tags.splice(oldIndex, 1);
            if (oldIndex < 0) newSearch.categories.tags.push(value as number);
          } else {
            newSearch.categories.tags = [value as number];
          }

          break;
        default:
          break;
      }
      return newSearch;
    });
  }

  reinitSearch(): void {
    this.searchStore.update(initialSearchState);
  }

  setPositionFilters(capsules: Capsule[]): void {
    this.searchStore.update((state) => ({
      ...state,
      filters: { position: getCapsulesDirections(capsules) },
    }));
  }

  addSelectedTag(tag: Tag | Tag[]): Observable<CompanySearch> {
    let newTags = (state: SearchState) => [];
    if (Array.isArray(tag)) {
      newTags = (state) => [
        ...state.search.categories.tags,
        ...tag.map((t) => t.id),
      ];
    } else {
      newTags = (state) => [...state.search.categories.tags, tag.id];
    }
    return this.setSearchStateFull((state) => ({
      categories: {
        ...state.search.categories,
        tags: newTags(state),
      },
    }));
  }
  removeSelectedTag(tag: Tag | Tag[]): Observable<CompanySearch> {
    let newTags = (state: SearchState) => [];
    if (Array.isArray(tag)) {
      newTags = (state) =>
        state.search.categories.tags.filter(
          (id) => !tag.map((t) => t.id).includes(id)
        );
    } else {
      newTags = (state) =>
        state.search.categories.tags.filter((id) => id !== tag.id);
    }
    return this.setSearchStateFull((state) => ({
      categories: {
        ...state.search.categories,
        tags: newTags(state),
      },
    }));
  }

  addSelectedCategory(tags: Tag[], catId: number, childrenIds: number[] = []) {
    this.setSearchStateFull((state) => ({
      categories: {
        ...state.search.categories,
        domainAndThematic: [
          ...state.search.categories.domainAndThematic,
          catId,
          ...childrenIds,
        ],
      },
    }));
    this.addSelectedTag(tags);
  }
  removeSelectedCategory(
    tags: Tag[],
    catId: number,
    childrenIds: number[] = []
  ) {
    this.setSearchStateFull((state) => ({
      categories: {
        ...state.search.categories,
        domainAndThematic: state.search.categories.domainAndThematic.filter(
          (id) => ![...childrenIds, catId].includes(id as number)
        ),
      },
    }));
    this.removeSelectedTag(tags);
  }
}

export const searchService = new SearchService(searchStore, query);

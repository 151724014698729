import React, { useState } from 'react';
import { Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import useDeviceDetect from '@hooks/useDeviceDetect';
import Logo from '../../atoms/Logo/Logo';
import PrimaryButton from '../../atoms/Buttons/PrimaryButton.view';
import TextInput from '../../atoms/TextInput/TextInput.view';
import OnBoardingScreen from './components/OnBoardingScreen';
import { getLoginButton, isSSOLogin } from '../../../domain/login';
import { useStyles } from './loginScreen.style';

export declare interface SignInScreenViewProps {
  onSignIn: (email: string) => void;
  message?: string;
  loading?: boolean;
  initialEmail?: string;
}

const SignInScreenView = ({
  onSignIn,
  message,
  loading = false,
  initialEmail,
}: SignInScreenViewProps) => {
  const { isMobile } = useDeviceDetect();
  const classes = useStyles({ isMobile });
  const [email, setEmail] = useState(initialEmail);

  const loginButton = getLoginButton({
    email,
    onSignIn,
  });

  return (
    <Grid container direction="row" className={classes.parent}>
      <Grid
        item
        xs={isMobile ? 12 : 5}
        container
        direction="column"
        justifyContent="space-between"
        alignItems="center"
        className={classes.container}
      >
        <Grid item>
          <Grid
            container
            direction="column"
            justifyContent="center"
            spacing={10}
            className={classes.contentWrapper}
          >
            <Grid item>
              <Logo />
              {!isSSOLogin && (
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextInput
                      type="email"
                      placeholder="Email"
                      onChange={(text) => setEmail(text)}
                      disabled={loading}
                    />
                  </Grid>
                </Grid>
              )}
            </Grid>
            <Grid item>
              <div className={classes.loginButton}>
                <PrimaryButton
                  onClick={loginButton.onClick}
                  isLoading={loading}
                >
                  {loginButton.text}
                </PrimaryButton>
              </div>
            </Grid>
            {!!message && (
              <Grid item>
                <div className={classes.message}>
                  <Typography variant="body2">{message}</Typography>
                </div>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
      {!isMobile && (
        <Grid item xs={7} className={classes.signInSlide}>
          <OnBoardingScreen />
        </Grid>
      )}
    </Grid>
  );
};

export default SignInScreenView;

import axios from 'axios';
import { SessionStore } from './session.store';
import { User } from '../user/user-permissions.model';

export const byPassLoginOnDevEnv = (
  data: { user: User; accessToken: string },
  sessionStore: SessionStore
) => {
  if ((window as any).env.REACT_APP_BY_PASS_LOGIN === 'true') {
    axios.defaults.headers.common['Authorization'] =
      'Bearer ' + data.accessToken;
    sessionStore.login(data);
  }
};

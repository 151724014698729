import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

const REACT_APP_SENTRY_DSN = (window as any).env.REACT_APP_SENTRY_DSN;
export const initSentry = () => {
  if (REACT_APP_SENTRY_DSN) {
    return Sentry.init({
      dsn: REACT_APP_SENTRY_DSN,
      integrations: [new Integrations.BrowserTracing()],

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 0.5,
      environment: (window as any).env.REACT_APP_SENTRY_ENVIRONMENT,
    });
  }
};

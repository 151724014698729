import { QueryEntity, toBoolean } from '@datorama/akita';
import { getCurrentUserValidation } from '@store/capsule/capsule-validators/capsule-validators.model';
import { Capsule } from '@store/capsule/capsule.model';
import { map, tap } from 'rxjs/operators';

import { SessionState, SessionStore, sessionStore } from './session.store';

export class SessionQuery extends QueryEntity<SessionState> {
  isLoggedIn$ = this.select((state) => {
    return toBoolean(state.accessToken);
  });
  currentUser$ = this.select((state) => state.user);

  isMobilePanelOpen$ = this.select((state) => state.ui.isMobilePanelOpen);
  settingsHelpSectionFocused$ = this.select(
    (state) => state.ui.settingsHelpSectionFocused
  );

  getCurrentUserValidation = (capsule: Capsule) =>
    this.currentUser$.pipe(
      map((user) => getCurrentUserValidation(capsule, user))
    );

  constructor(protected store: SessionStore) {
    super(store);
  }
}

export const sessionQuery = new SessionQuery(sessionStore);

import React from 'react';

import { OnboardingScreenSlide } from './onboardingscreen-slide.model';

import OnBoardingScreenView from './OnBoardingScreen.view';
import slide1 from './assets/slide1.jpg';
import slide2 from './assets/slide2.jpg';
import slide3 from './assets/slide3.jpg';

const slides: OnboardingScreenSlide[] = [
  {
    image: slide1,
    text: 'PARTAGER FACILEMENT TOUS LES PROBLÈMES, DIFFICULTÉS, IRRITANTS ET POTENTIEL D’AMÉLIORATION',
  },
  {
    image: slide2,
    text: 'FAVORISER LA RÉSOLUTION AJUSTÉE EN RÉCOLTANT LES AVIS ET SOLUTIONS DANS LES PÉRIMÈTRES CONCERNÉS',
  },
  {
    image: slide3,
    text: 'DÉMULTIPLIER L’AMÉLIORATION ET LE PARTAGE DE BONNES PRATIQUES DANS TOUTE L’ORGANISATION',
  },
];

const OnBoardingScreen = () => {
  const onReachEnd = () => console.log('onReachEnd');

  return <OnBoardingScreenView slides={slides} onReachEnd={onReachEnd} />;
};

export default OnBoardingScreen;

import { Capsule } from '@store/capsule/capsule.model';
import { HomeType } from '@zdlibs/domain-capsule';
import { mergeMap, Observable, of, take } from 'rxjs';
import { capsuleService } from './capsule/capsule.service';
import { categoryService } from './category/category.service';
import { companyService } from './company/company.service';
import { constellationService } from './constellation/constellation.service';
import { labelService } from './label/label.service';
import { userNotificationService } from './notification/notification.service';
import { searchService } from './search/search.service';
import { sessionService } from './session/session.service';
import { userService } from './user/user.service';

export const initStore = (homeType?: HomeType) =>
  sessionService
    .setHeaderAccessToken()
    .pipe(
      mergeMap((isLogged) => {
        if (!isLogged) {
          return of(new Error('isNotLogged'));
        }
        let getCapsules: () => Observable<boolean | Capsule[]>;
        if (homeType === 'all') {
          getCapsules = () => capsuleService.toggleCapsulesHome();
        } else {
          getCapsules = () => capsuleService.getCapsules();
        }
        return userService.getCompanyUsers().pipe(
          mergeMap(() => companyService.getCompany()),
          mergeMap(() => constellationService.getConstellations()),
          mergeMap(() => getCapsules()),
          mergeMap(() => searchService.getSearches()),
          mergeMap(() => userNotificationService.getNotifications()),
          mergeMap(() => categoryService.getCategoriesAndTags()),
          mergeMap(() => labelService.findAll())
        );
      })
    )
    .pipe(take(1));

import './hide-scrollbars.css';
import './mui-input-placeholder.css';
import 'moment/locale/fr';

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';

import { akitaDevtools } from '@datorama/akita';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';

import * as serviceWorker from './serviceWorker';
import theme from './theme/';

import App from './App';
import { AppLayout } from './components/Layouts/AppLayout';
import ScrollToTop from '@routes/AppRouter/ScrollToTop';

akitaDevtools();

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppLayout>
        <Router>
          <ScrollToTop />
          <App />
        </Router>
      </AppLayout>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

serviceWorker.unregister();
export { CAPSULE_STATUS_TITLE } from '@store/capsule/capsule-status/capsule-status.model';

import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';

import { SettingsHelpSection } from '../../components/organisms/SettingsHelp/settingsHelp-section.enum';
import { ROUTES } from '../../routes/routes';
import { User } from '../user/user-permissions.model';
import { Session } from './session.model';

import * as storage from '../storage';

export interface SessionState extends EntityState<Session> {
  user: User;
  accessToken: string;
  ui: {
    isMobilePanelOpen: boolean;
    settingsHelpSectionFocused: SettingsHelpSection;
  };
}

export function createInitialSessionState(): SessionState {
  return {
    user: storage.getSession().user ? storage.getSession().user : null,
    accessToken: storage.getSession().accessToken
      ? storage.getSession().accessToken
      : null,
    ...storage.getSession(),
    ui: {
      isMobilePanelOpen: false,
      settingsHelpSectionFocused: SettingsHelpSection.NONE,
    },
  };
}

@StoreConfig({
  name: 'session',
})
export class SessionStore extends EntityStore<SessionState> {
  constructor() {
    super(createInitialSessionState());
  }

  login(session: Session) {
    this.update(session);
    storage.saveSession(session);
  }

  updateUser(user: User) {
    const currentUser = JSON.parse(JSON.stringify(this.getValue().user));

    currentUser.firstName = user.firstName;
    currentUser.lastName = user.lastName;
    currentUser.email = user.email;
    currentUser.city = user.city;
    currentUser.profilePhoto = user.profilePhoto;
    currentUser.profilePhotoThumb = user.profilePhotoThumb;
    currentUser.position = user.position;
    currentUser.phone = user.phone;
    currentUser.company = user.company;
    currentUser.role = user.role;
    currentUser.userPoints = user.userPoints;
    currentUser.isNotifiedOnOwnedCapsuleChanges =
      user.isNotifiedOnOwnedCapsuleChanges;
    currentUser.isNotifiedOnTrendingCapsules =
      user.isNotifiedOnTrendingCapsules;
    currentUser.isNotifiedOnConstellationsActivity =
      user.isNotifiedOnConstellationsActivity;
    currentUser.isNotifiedOnMentions = user.isNotifiedOnMentions;

    this.update({
      user: currentUser,
      accessToken: storage.getSession().accessToken,
    });
    storage.saveSession({
      user: currentUser,
      accessToken: storage.getSession().accessToken,
    });
  }

  logout(noRedirect?: boolean) {
    storage.clearSesssion();
    this.update(createInitialSessionState());
    if (!noRedirect) window.location.replace(ROUTES.ROOT);
  }
}

export const sessionStore = new SessionStore();

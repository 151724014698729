/**
 * This normalizes any name with [Uppercase first letter of each word], and [lowercase other letters].
 *
 * Examples:
 * normalizeNames('john') // 'John'
 * normalizeNames('john doe') // 'John Doe'
 * normalizeNames('JOHN') // 'John'
 *
 * @param {string} name
 * @type {string}
 *
 */
export const normalizeNames = (name: string): string =>
  name ? name[0].toUpperCase() + name.slice(1).toLowerCase() : 'Aucun titre';

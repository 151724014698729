import { constellationService } from '../store/constellation/constellation.service';
import { capsuleService } from '../store/capsule/capsule.service';
import { capsulePollService } from '../store/capsule-poll/capsule-poll.service';
import { solutionService } from '../store/solution/solution.service';
import { userNotificationService } from '../store/notification/notification.service';

export const entityEventsHandler = (eventName, entity, pathname) => {
  if (eventName === 'constellation.inserted') {
    constellationService.getConstellations().subscribe();
  }

  if (eventName === 'constellation.deleted') {
    constellationService.getConstellations().subscribe();
  }

  if (eventName === 'constellation.updated') {
    if (entity) {
      constellationService.getConstellation(entity.id).subscribe();
    }
  }

  if (eventName === 'capsule.updated') {
    capsuleService.getOneCapsule(entity.id).subscribe();
  }

  if (eventName === 'capsule_poll.inserted') {
    if (entity && pathname === `/home/capsules/${entity.capsuleId}`) {
      capsulePollService.getPolls(entity.capsuleId).subscribe();
    }
  }

  if (eventName === 'capsule_poll.updated') {
    if (entity && pathname === `/home/capsules/${entity.capsuleId}`) {
      capsulePollService.getPolls(entity.capsuleId).subscribe();
    }
  }

  if (eventName === 'capsule_poll_user_answer.inserted') {
    if (entity && pathname === `/home/capsules/${entity.capsulePollId}`) {
      capsulePollService.getPolls(entity.capsulePollId).subscribe();
    }
  }

  if (eventName === 'solution.inserted') {
    if (entity && pathname === `/home/capsules/${entity.capsuleId}`) {
      solutionService.getCapsuleSolutions(entity.capsuleId).subscribe();
    }
  }

  if (eventName === 'solution.updated') {
    if (entity && pathname === `/home/capsules/${entity.capsuleId}`) {
      solutionService.getCapsuleSolutions(entity.capsuleId).subscribe();
    }
  }

  if (eventName === 'solution.deleted') {
    if (entity && pathname === `/home/capsules/${entity.capsuleId}`) {
      solutionService.getCapsuleSolutions(entity.capsuleId).subscribe();
    }
  }

  if (eventName === 'notification.inserted') {
    userNotificationService.getNotifications().subscribe();
  }
};

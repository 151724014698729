import { ID } from '@datorama/akita';
import axios from 'axios';
import { concatMap, from, Observable, take } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { Tag } from '../tag/tag.model';
import {
  ConstellationUser,
  formatAdminConstellationUser,
} from './constellation-user.model';
import { Constellation, UpdateConstellation } from './constellation.model';
import { constellationQuery } from './constellation.query';
import { ConstellationStore, constellationStore } from './constellation.store';
import { CreateConstellation } from './create-constellation.model';
import { capsuleService } from '../capsule/capsule.service';
import { fromRequest } from '../../utils/utils';
import { sessionService } from '../session/session.service';
import { isAdmin } from '@zdlibs/domain/user';

export class ConstellationService {
  constructor(private constellationStore: ConstellationStore) {}

  getConstellations(): Observable<Constellation[]> {
    return fromRequest(
      axios.get(`${(window as any).env.REACT_APP_BACK_WEB_URL}/constellations`)
    ).pipe(
      tap((res) => {
        const user = sessionService.currentUser();
        let constellations: Constellation[] = res.data;
        /*if (isAdmin(user))
          constellations = constellations.map((constellation) => ({
            ...constellation,
            userPermissions: formatAdminConstellationUser(user, constellation),
          }));*/
        this.constellationStore.set(constellations);
      }),
      map((res) => res.data),
      take(1)
    );
  }

  getConstellation(constellationId: ID): Observable<Constellation> {
    return from(
      axios.get(
        `${
          (window as any).env.REACT_APP_BACK_WEB_URL
        }/constellations/${constellationId}`
      )
    ).pipe(
      tap((res) => {
        const user = sessionService.currentUser();
        if (isAdmin(user))
          res.data = {
            ...res.data,
            userPermissions: formatAdminConstellationUser(user, res.data),
          };
        this.constellationStore.upsert(constellationId, (constellation) => {
          return {
            ...constellation,
            ...res.data,
          };
        });
      }),
      map((res) => {
        return constellationQuery.getEntity(constellationId);
      }),
      take(1)
    );
  }

  inviteToConstellation(
    constellation: Constellation
  ): Observable<Constellation> {
    return from(
      axios.post(
        `${(window as any).env.REACT_APP_BACK_WEB_URL}/constellations/${
          constellation.id
        }/invite`,
        constellation
      )
    ).pipe(
      tap(async (res) => {
        this.constellationStore.upsert(constellation.id, (constellation) => {
          return {
            ...constellation,
            ...res.data,
          };
        });
      }),
      map((res) => {
        return constellationQuery.getEntity(constellation.id);
      })
    );
  }
  join(constellationId: ID): Observable<Constellation> {
    return from(
      axios.get(
        `${
          (window as any).env.REACT_APP_BACK_WEB_URL
        }/constellations/${constellationId}/accept-invite`
      )
    ).pipe(
      take(1),
      tap(async (res) => {
        this.constellationStore.upsert(constellationId, (constellation) => {
          return {
            ...constellation,
            ...res.data,
          };
        });
      }),
      map(() => {
        return constellationQuery.getEntity(constellationId);
      }),
      concatMap((constellation) =>
        capsuleService.getCapsules().pipe(map(() => constellation))
      )
    );
  }

  allow(constellationId: ID, userId: ID): Observable<Constellation> {
    return from(
      axios.post(
        `${
          (window as any).env.REACT_APP_BACK_WEB_URL
        }/constellations/${constellationId}/allow/${userId}`
      )
    ).pipe(
      tap(async (res) => {
        this.constellationStore.upsert(constellationId, (constellation) => {
          return {
            ...constellation,
            ...res.data,
          };
        });
      }),
      map(() => {
        return constellationQuery.getEntity(constellationId);
      })
    );
  }

  leave(constellationId: ID): Observable<boolean> {
    return from(
      axios.get(
        `${
          (window as any).env.REACT_APP_BACK_WEB_URL
        }/constellations/${constellationId}/leave`
      )
    ).pipe(
      take(1),
      tap(async (res) => {
        this.constellationStore.upsert(constellationId, (constellation) => {
          return {
            ...constellation,
            ...res.data,
          };
        });
      }),
      concatMap(() =>
        capsuleService.getCapsules().pipe(
          tap(() => this.constellationStore.setActive(null)),
          map(() => true)
        )
      )
    );
  }

  removeMember(constellationId: ID, memberId: ID): Observable<Constellation> {
    return from(
      axios.delete(
        `${
          (window as any).env.REACT_APP_BACK_WEB_URL
        }/constellations/${constellationId}/members/${memberId}`
      )
    ).pipe(
      tap(async (res) => {
        this.constellationStore.upsert(constellationId, (constellation) => {
          return {
            ...constellation,
            ...res.data,
          };
        });
      }),
      map((res) => {
        return constellationQuery.getEntity(constellationId);
      })
    );
  }

  createConstellation(
    constellation: CreateConstellation
  ): Observable<Constellation> {
    return from(
      axios.post(
        (window as any).env.REACT_APP_BACK_WEB_URL + '/constellations',
        constellation
      )
    ).pipe(
      tap((res) => {
        this.constellationStore.add(res.data);
      }),
      map((res) => res.data),
      take(1)
    );
  }

  patchConstellation(
    constellationId: number,
    updatedFields: Partial<UpdateConstellation>
  ): Observable<Constellation> {
    return from(
      axios.patch(
        `${
          (window as any).env.REACT_APP_BACK_WEB_URL
        }/constellations/${constellationId}`,
        updatedFields
      )
    ).pipe(
      tap((res) => {
        this.constellationStore.update(constellationId, (constellation) => {
          return {
            ...constellation,
            ...res.data,
          };
        });
      }),
      map((res) => {
        return constellationQuery.getEntity(constellationId);
      })
    );
  }

  removeTag(constellationId: number, tag: Tag): Observable<Constellation> {
    return from(
      axios.delete(
        `${
          (window as any).env.REACT_APP_BACK_WEB_URL
        }/constellations/${constellationId}/tags/${tag.id}`
      )
    ).pipe(
      tap((res) => {
        this.constellationStore.update(constellationId, (constellation) => {
          return {
            ...constellation,
            tags: constellation.tags.filter((t) => t.id !== res.data),
          };
        });
      }),
      map((res) => {
        return constellationQuery.getEntity(constellationId);
      })
    );
  }

  patchConstellationUserMe(
    constellationId: number,
    updatedFields: Partial<ConstellationUser>
  ): Observable<Constellation> {
    return from(
      axios.patch(
        `${
          (window as any).env.REACT_APP_BACK_WEB_URL
        }/constellations/${constellationId}/user`,
        updatedFields
      )
    ).pipe(
      tap((res) => {
        this.constellationStore.update(constellationId, (constellation) => {
          return {
            ...constellation,
            userPermissions: {
              ...res.data,
            },
            // ...res.data,
          };
        });
      }),
      map((res) => {
        return constellationQuery.getEntity(res.data.constellationId);
      })
    );
  }

  setUploadingImage(isUploadingImage: boolean): Observable<Constellation> {
    return this.constellationStore.update((state) => ({
      ...state,
      ui: {
        ...state.ui,
        isUploadingImage,
      },
    }));
  }

  patchConstellationImage(
    constellationId: ID,
    files: File[]
  ): Observable<Constellation> {
    const formData = new FormData();
    formData.append('file', files[0]);

    return from(
      axios.patch(
        `${
          (window as any).env.REACT_APP_BACK_WEB_URL
        }/constellations/${constellationId}/image`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      )
    ).pipe(
      tap((res) => {
        this.constellationStore.update(constellationId, (constellation) => {
          return {
            ...constellation,
            ...res.data,
          };
        });
        this.setUploadingImage(false);
      }),
      map((res) => {
        return constellationQuery.getEntity(constellationId);
      })
    );
  }

  archive(constellationId: ID) {
    return from(
      axios.delete(
        `${
          (window as any).env.REACT_APP_BACK_WEB_URL
        }/constellations/${constellationId}`
      )
    ).pipe(
      tap((res) => {
        console.log('Archive Constl');
        this.getConstellations().subscribe();

        capsuleService.getCapsules().subscribe();
      }),
      map((res) => {
        return;
      })
    );
  }

  patchInviteRoles(
    constellationId: ID,
    constellation: Constellation,
    invites: ConstellationUser[]
  ): Observable<Constellation> {
    return from(
      axios.patch(
        `${
          (window as any).env.REACT_APP_BACK_WEB_URL
        }/constellations/${constellationId}/invite`,
        invites
      )
    ).pipe(
      tap(async (res) => {
        this.constellationStore.upsert(constellationId, (constellation) => {
          return {
            ...constellation,
            ...res.data,
          };
        });
      }),
      map((res) => {
        return constellationQuery.getEntity(constellationId);
      })
    );
  }

  // postConstellation(constellation: ConstellationToCreate, file: File): Observable<Constellation> {
  // 	const constellationFormData = new FormData()
  // 	constellationFormData.append('name', constellation.name)
  // 	constellationFormData.append('description', constellation.description)
  // 	constellationFormData.append('mainManagerId', constellation.mainManagerId.toString())
  // 	constellationFormData.append('membersIds', constellation.membersIds.join(','))
  // 	constellationFormData.append('tags', constellation.tags.join(','))
  // 	if (file) {
  // 		constellationFormData.append('file', file)
  // 	}
  // 	return fromRequest(
  // 		axios.post((window as any).env.REACT_APP_BACK_WEB_URL + '/constellations', constellationFormData, {
  // 			headers: { 'Content-Type': undefined },
  // 		}),
  // 	).pipe(
  // 		switchMap(res => {
  // 			this.constellationStore.add(res.data)
  // 			return of(res.data)
  // 		}),
  // 	)
  // }
}

export const constellationService = new ConstellationService(
  constellationStore
);

import { validateEmail } from '../../utils/validate';

const SSO_URL = (window as any).env.REACT_APP_API_SOCKET_URL + '/auth/startSSO';

export const isSSOLogin =
  (window as any).env.REACT_APP_LOGIN_WITH_SSO === 'true';

interface GetLoginButton {
  email: string;
  onSignIn: (email: string) => void;
}

export const getLoginButton = ({
  email,
  onSignIn,
}: GetLoginButton): { onClick: () => void; text: string } => ({
  onClick: isSSOLogin
    ? () => window.location.assign(SSO_URL)
    : () => {
        if (validateEmail(email)) onSignIn(email);
      },
  text: isSSOLogin ? 'Connection SSo' : 'Se connecter',
});

import React from 'react';
import { Helmet } from 'react-helmet';

import SignInScreen from '../../components/screens/LoginScreen/SignInScreen';

export const SignInPage = () => {
  return (
    <>
      <Helmet>
        <title>
          {(window as any).env.REACT_APP_APP_NAME
            ? (window as any).env.REACT_APP_APP_NAME
            : 'ZideeUp'}{' '}
          - Connexion
        </title>
      </Helmet>
      <SignInScreen />
    </>
  );
};

export default SignInPage;

import { Capsule } from '../../capsule/capsule.model';
import { Search } from '../search.model';
import { User } from '../../user/user-permissions.model';
import {
  isCapsuleActivator,
  isCapsuleCreator,
  isCapsulePilote,
  isCapsuleResolver,
  isParticipatingUser,
} from '@zdlibs/domain/user';

export const filterMyCapsules = (
  capsules: Capsule[],
  search: Search,
  currentUser: User
): Capsule[] => {
  if (search.showCapsulesIParticipate) {
    capsules = capsules.filter(isParticipatingUser(currentUser.id));
  }
  if (search.showMyFavorites) {
    capsules = capsules.filter((c) => c.isUserFavorite === true);
  }
  if (search.showCapsulesICreatedOrPilote) {
    capsules = capsules.filter(
      (c) => isCapsuleCreator(currentUser)(c) || isCapsulePilote(currentUser)(c)
    );
  }
  if (search.showCapsulesIActivated) {
    capsules = capsules.filter(isCapsuleActivator(currentUser));
  }
  if (search.showCapsulesIResolved) {
    capsules = capsules.filter(isCapsuleResolver(currentUser));
  }

  if (search.showCapsulesIValidator === true) {
    capsules = capsules.filter((c) => c.isValidator);
  }
  // n'existe pas encore
  /*if (search.showTrendingCapsules) {
    capsules = capsules.filter((c) => c.isTrendy === true);
  }*/

  return capsules;
};

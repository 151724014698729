import React from 'react';

import PasswordResetView, {
  PasswordResetViewProps,
} from './PasswordReset.view';

const PasswordReset = (props: PasswordResetViewProps) => (
  <PasswordResetView {...props} />
);

export default PasswordReset;

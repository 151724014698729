import { Route, Switch } from 'react-router-dom';
import React from 'react';
import { ROUTES } from '../routes';
import ForgottenPasswordPage from '../../pages/ForgottenPassword/ForgottenPassword.page';
import ResetPasswordPage from '../../pages/ResetPassword/ResetPassword.page';
import SignInPage from '../../pages/SignInPasswordLess/SignInPasswordLess.page';
import SignUpPage from '../../pages/SignUp/SignUp.page';
import VerifyEmailPage from '../../pages/VerifyEmail/VerifyEmail.page';
import VerifyLoginCodePage from '../../pages/VerifyLoginCode/VerifyLoginCode.page';

export const NotLoggedRoutes = () => (
  <Switch>
    <Route path={ROUTES.EMAIL_VERIF} component={VerifyEmailPage} />
    <Route exact path={ROUTES.RESET_PASSWORD} component={ResetPasswordPage} />
    <Route
      exact
      path={ROUTES.FORGOTTEN_PASSWORD}
      component={ForgottenPasswordPage}
    />
    <Route path={ROUTES.SIGN_IN} component={SignInPage} />
    <Route path={ROUTES.SIGN_IN_LINK} component={SignInPage} />
    <Route path={ROUTES.SIGN_IN_VERIF} component={VerifyLoginCodePage} />
    <Route exact path={ROUTES.SIGN_UP} component={SignUpPage} />
    <Route component={SignInPage} />
  </Switch>
);

import Catamaran from '../assets/fonts/catamaran-v14-latin-regular.woff';
import Catamaran2 from '../assets/fonts/catamaran-v14-latin-regular.woff2';
import CatamaranSemiBold from '../assets/fonts/catamaran-v14-latin-600.woff';
import CatamaranSemiBold2 from '../assets/fonts/catamaran-v14-latin-600.woff2';
import CatamaranBold from '../assets/fonts/catamaran-v14-latin-700.woff';
import CatamaranBold2 from '../assets/fonts/catamaran-v14-latin-700.woff2';

export const catamaranFont = {
  fontFamily: 'Catamaran',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
    local(''),
    url(${Catamaran}) format('woff'),
    url(${Catamaran2}) format('woff2')
  `,
};

export const catamaranFontSemiBold = {
  fontFamily: 'Catamaran',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 600,
  src: `
    local(''),
    url(${CatamaranSemiBold}) format('woff'),
    url(${CatamaranSemiBold2}) format('woff2')
  `,
};

export const catamaranFontBold = {
  fontFamily: 'Catamaran',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 700,
  src: `
    local(''),
    url(${CatamaranBold}) format('woff'),
    url(${CatamaranBold2}) format('woff2')
  `,
};

export default catamaranFont;

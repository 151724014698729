import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Tag } from '../tag/tag.model';
import { Category } from '@zdlibs/domain-category';

export interface TagTable {
  domainParent?: Category['id'];
  thematicParent?: Category['id'];
  domain?: Category[];
  thematic?: Category[];
  tag?: Tag[];
}

export interface CategoryState extends EntityState<Category> {
  isDomainCategoriesEnabled: boolean;
  isThematicCategoriesEnabled: boolean;
  ui: {
    tagTable?: TagTable;
  };
}
export const initialCategoryState: CategoryState = {
  isDomainCategoriesEnabled: false,
  isThematicCategoriesEnabled: false,
  ui: {},
};

@StoreConfig({
  name: 'category',
})
export class CategoryStore extends EntityStore<CategoryState> {
  constructor() {
    super(initialCategoryState);
  }
}

export const categoryStore = new CategoryStore();

import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';

import { UserNotification } from './notification.model';

// export type TagState = EntityState<Tag>

export interface UserNotificationState extends EntityState<UserNotification> {}
export const initialUserNotificationState: UserNotificationState = {};

@StoreConfig({
  name: 'notification',
  resettable: true,
})
export class UserNotificationStore extends EntityStore<UserNotificationState> {
  constructor() {
    super(initialUserNotificationState);
  }
}

export const userNotificationStore = new UserNotificationStore();

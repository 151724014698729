import {
  CategoryState,
  categoryStore,
  CategoryStore,
  TagTable,
} from './categoryStore';
import { ID, QueryEntity } from '@datorama/akita';
import {
  DomainArborescence,
  TagsArborescence,
  ThematicArborescence,
} from './category.model';
import { tagQuery } from '../tag/tag.query';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Tag } from '../tag/tag.model';
import * as R from 'ramda';
import {
  Category,
  CategoryType,
  CategoryUpdate,
} from '@zdlibs/domain-category';

const findObjectIndexById = (obj: CategoryState, id: ID, path: string[]) =>
  R.findIndex(R.propEq('id', id))(R.view(R.lensPath(path))(obj));

const updateKeyPath = (
  obj: CategoryState,
  id: ID,
  key: string,
  type: CategoryType
) => {
  const path = ['ui', 'tagTable', type];

  return R.lensPath([...path, findObjectIndexById(obj, id, path), key]);
};

export class CategoryQuery extends QueryEntity<CategoryState> {
  constructor(protected store: CategoryStore) {
    super(store);
  }
  tagTable$: Observable<TagTable> = this.select().pipe(
    map((category) => category.ui.tagTable)
  );

  updateTagTableEntity(id: Category['id'], category: CategoryUpdate) {
    const title = category.type === 'tag' ? 'text' : 'name';

    categoryStore.update((state) => {
      return {
        ...R.set(
          updateKeyPath(state, id, title, category.type),
          category.newName
        )(state),
      };
    });
  }
  deleteTagTableEntity(id: Category['id'], type: CategoryType) {
    categoryStore.update((state) => {
      return {
        ...R.over(
          R.lensPath(['ui', 'tagTable', type]),
          R.reject(R.propEq('id', id)),
          state
        ),
      };
    });
  }
  addTagTableEntity(newEntity: Tag | Category, type: CategoryType) {
    categoryStore.update((state) => ({
      ...state,
      ui: {
        ...state.ui,
        tagTable: {
          ...state.ui.tagTable,
          [type]: [...state.ui.tagTable[type], newEntity],
        },
      },
    }));
  }

  getDomainChildren(domainId: Category['id']) {
    const thematic = this.getAll().filter(
      ({ parentId }) => parentId === domainId
    );
    categoryStore.update((state) => ({
      ...state,
      ui: {
        ...state.ui,
        tagTable: R.pipe(
          R.assoc('thematic', thematic),
          R.assoc('domainParent', domainId),
          R.dissoc('tag')
        )(state.ui.tagTable),
      },
    }));
  }
  getThematicChildren(thematicId: Category['id']) {
    const tags = tagQuery
      .getAll()
      .filter(({ categoryId }) => categoryId === thematicId);
    categoryStore.update((state) => ({
      ...state,
      ui: {
        ...state.ui,
        tagTable: R.pipe(
          R.assoc('tag', tags),
          R.assoc('thematicParent', thematicId)
        )(state.ui.tagTable),
      },
    }));
  }
  getTagsArborescence(): TagsArborescence {
    const categories = this.getAll();
    const tags = tagQuery.getAll();

    if (
      !this.getValue().isDomainCategoriesEnabled &&
      !this.getValue().isThematicCategoriesEnabled
    )
      return { type: 'tag', categories: tags };

    const domains = R.filter<Category>(({ type }) => type === 'domain')(
      categories
    );

    const thematics = R.filter<Category>((c) => c.type === 'thematic')(
      categories
    );
    const getChildrenTag = (thematic: Category) =>
      R.filter((tag: Tag) => thematic.id === tag.categoryId)(tags);

    if (
      !this.getValue().isDomainCategoriesEnabled &&
      this.getValue().isThematicCategoriesEnabled
    )
      return {
        type: 'thematic',
        categories: R.map<Category, ThematicArborescence>((theme) =>
          R.assoc('tags', getChildrenTag(theme), theme)
        )(thematics),
      };

    const getChildrenThematic = (domain: Category): ThematicArborescence[] =>
      R.pipe(
        R.filter((theme: Category) => theme.parentId === domain.id),
        R.map((theme) => R.assoc('tags', getChildrenTag(theme), theme))
      )(thematics);

    return {
      type: 'domain',
      categories: R.map<Category, DomainArborescence>((domain) =>
        R.assoc('thematics', getChildrenThematic(domain), domain)
      )(domains),
    };
  }
  isCategoryEnabled(): boolean {
    const config = this.getValue();
    return (
      config.isDomainCategoriesEnabled || config.isThematicCategoriesEnabled
    );
  }
}

export const categoryQuery = new CategoryQuery(categoryStore);

import CompanyLogoImage from '../assets/ZIDEEUP.svg';
import RoundLogoImage from '../assets/roundLogo.png';

export function getWindowEnv(envVariableName: string): any {
  if (typeof envVariableName === 'string') {
    return (window as any).env[envVariableName];
  }
  return (window as any).env || {};
}

export const hasCompanyLogo = () => !!getWindowEnv('REACT_APP_COMPANY_LOGO');
export const getCompanyLogoPath = () =>
  getWindowEnv('REACT_APP_COMPANY_BRANDING_NAME') || CompanyLogoImage;
export const getRoundLogoPath = () =>
  getWindowEnv('REACT_APP_COMPANY_LOGO') || RoundLogoImage;

import { ID } from '@datorama/akita';
import axios from 'axios';
import { from, Observable, of, take } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { Capsule } from '../capsule/capsule.model';
import { capsuleStore } from '../capsule/capsule.store';
import { VoteType } from '../vote/vote-type.enum';
import { SolutionComment } from './solution-comment/solution-comment.model';
import { SolutionVote } from './solution-vote/solution-vote.model';
import { Solution } from './solution.model';
import { solutionQuery } from './solution.query';
import { SolutionStore, solutionStore } from './solution.store';
import { MessageParams } from '@store/modal/modal.model';

export class SolutionService {
  constructor(private solutionStore: SolutionStore) {}

  getCapsuleSolutions(capsuleId: ID): Observable<Solution[]> {
    return from(
      axios.get(
        `${
          (window as any)?.env?.REACT_APP_BACK_WEB_URL
        }/capsules/${capsuleId}/solutions`
      )
    )
      .pipe(
        tap((res) => {
          this.solutionStore.upsertMany(res.data);
        }),
        map((res) => {
          return res.data;
        }),
        take(1)
      )
      .pipe(catchError((e) => of(e)));
  }

  postSolution(
    capsuleId: ID,
    description: string,
    files: File[]
  ): Observable<Solution> {
    const formData = new FormData();
    formData.append('file', files[0]);
    formData.append('description', description);

    return from(
      axios.post(
        `${
          (window as any).env.REACT_APP_BACK_WEB_URL
        }/capsules/${capsuleId}/solutions`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      )
    ).pipe(
      tap((res) =>
        capsuleStore.update(capsuleId, (capsule) => {
          return {
            ...capsule,
            nbSolutions: capsule.nbSolutions + 1,
          };
        })
      ),
      map((res) => {
        this.solutionStore.add(res.data);
        return res.data;
      })
    );
  }

  patchSolution(solutionId: ID, description: string): Observable<Solution[]> {
    return from(
      axios.patch(
        `${(window as any).env.REACT_APP_BACK_WEB_URL}/solutions/${solutionId}`,
        {
          description,
        }
      )
    ).pipe(
      tap((res) => {
        this.solutionStore.update(solutionId, (solution) => ({
          ...solution,
          ...res.data,
        }));
      }),
      map((res) => {
        return res.data;
      })
    );
  }

  deleteSolution(capsuleId: ID, solutionId: ID): Observable<MessageParams> {
    return from(
      axios.delete(
        `${(window as any).env.REACT_APP_BACK_WEB_URL}/solutions/${solutionId}`
      )
    ).pipe(
      take(1),
      tap(() => {
        this.solutionStore.remove(solutionId);
        capsuleStore.update(capsuleId, (capsule) => {
          return {
            ...capsule,
            nbSolutions: capsule.nbSolutions - 1,
          };
        });
      }),
      map(() => {
        return {
          content: 'La solution a été supprimée',
          type: 'success',
        } as MessageParams;
      }),
      catchError((err) => {
        return of({
          content: err?.response?.data?.message as string,
          type: 'error',
        } as MessageParams);
      })
    );
  }

  vote(solutionId: ID, solutionVote: SolutionVote): Observable<Solution> {
    return from(
      axios.post<Capsule>(
        `${
          (window as any).env.REACT_APP_BACK_WEB_URL
        }/solutions/${solutionId}/vote`,
        solutionVote
      )
    ).pipe(
      tap((res) => {
        this.solutionStore.update(solutionId, (solution) => {
          return {
            ...solution,
            ...res.data,
          };
        });
      }),
      tap((res) => {
        capsuleStore.update(res.data.id, (capsule) => {
          return {
            ...capsule,
            capsulePoints:
              solutionVote.voteType === VoteType.DOWN
                ? capsule.capsulePoints - 1
                : capsule.capsulePoints + 1,
          };
        });
      }),
      map((res) => {
        return solutionQuery.getEntity(res.data.id);
      })
    );
  }

  //  _____                                      _
  // /  __ \                                    | |
  // | /  \/ ___  _ __ ___  _ __ ___   ___ _ __ | |_ ___
  // | |    / _ \| '_ ` _ \| '_ ` _ \ / _ \ '_ \| __/ __|
  // | \__/\ (_) | | | | | | | | | | |  __/ | | | |_\__ \
  //  \____/\___/|_| |_| |_|_| |_| |_|\___|_| |_|\__|___/

  getSolutionComments(
    capsuleId: ID,
    solutionId: ID
  ): Observable<SolutionComment[]> {
    this.solutionStore.setLoading(true);
    return from(
      axios.get(
        `${
          (window as any).env.REACT_APP_BACK_WEB_URL
        }/solutions/${solutionId}/comments`
      )
    ).pipe(
      tap((res) => {
        this.solutionStore.update(solutionId, (solution) => {
          return {
            ...solution,
            comments: [...res.data],
          };
        });
        this.solutionStore.setLoading(false);
      }),
      map((res) => {
        return res.data;
      })
    );
  }

  postSolutionComment(
    solutionId: ID,
    text: string
  ): Observable<SolutionComment> {
    return from(
      axios.post(
        `${
          (window as any).env.REACT_APP_BACK_WEB_URL
        }/solutions/${solutionId}/comments`,
        {
          text,
        }
      )
    ).pipe(
      tap((res) => {
        this.solutionStore.update(solutionId, (solution) => {
          return {
            ...solution,
            nbComments: solution.nbComments + 1,
            comments: solution.comments
              ? [...solution.comments, res.data]
              : res.data,
          };
        });
      }),
      // TODO: This causses refresh and closing of Solution's comments section.
      // tap(res => {
      // 	capsuleStore.update(res.data.capsuleId, capsule => {
      // 		return {
      // 			...capsule,
      // 			capsulePoints: capsule.capsulePoints + 2,
      // 		}
      // 	})
      // }),
      map((res) => {
        return res.data;
      })
    );
  }

  patchSolutionComment(
    solutionId: ID,
    commentId: ID,
    text: string
  ): Observable<Solution> {
    return from(
      axios.patch(
        `${
          (window as any).env.REACT_APP_BACK_WEB_URL
        }/solutions/${solutionId}/comments/${commentId}`,
        { text }
      )
    ).pipe(
      tap((res) => {
        this.solutionStore.update(solutionId, (solution) => {
          // res.data is CapsuleComment
          const comments = solution.comments ? [...solution.comments] : [];
          const idx = comments.findIndex((comment) => comment.id === commentId);
          comments[idx] = { ...comments[idx], ...res.data };
          return {
            ...solution,
            comments,
          };
        });
      }),
      map((res) => {
        return solutionQuery.getEntity(solutionId);
      })
    );
  }

  removeSolutionComment(solutionId: ID, commentId: ID): Observable<Solution> {
    return from(
      axios.delete(
        `${
          (window as any).env.REACT_APP_BACK_WEB_URL
        }/solutions/${solutionId}/comments/${commentId}`
      )
    ).pipe(
      tap((res) => {
        this.solutionStore.update(solutionId, (solution) => {
          const comments = solution.comments ? [...solution.comments] : [];
          const idx = comments.findIndex((comment) => comment.id === commentId);
          comments[idx] = null;
          return {
            ...solution,
            nbComments: solution.nbComments - 1,
            comments: comments.filter(Boolean),
          };
        });
      }),
      map((res) => {
        return solutionQuery.getEntity(solutionId);
      })
    );
  }

  // 	    ___  _   _             _                          _
  // 	   / _ \| | | |           | |                        | |
  //    / /_\ \ |_| |_ __ _  ___| |__  _ __ ___   ___ _ __ | |_ ___
  //    |  _  | __| __/ _` |/ __| '_ \| '_ ` _ \ / _ \ '_ \| __/ __|
  //    | | | | |_| || (_| | (__| | | | | | | | |  __/ | | | |_\__ \
  //    \_| |_/\__|\__\__,_|\___|_| |_|_| |_| |_|\___|_| |_|\__|___/

  getSolutionAttachments(solutionId: ID): Observable<Solution> {
    return from(
      axios.get(
        `${
          (window as any).env.REACT_APP_BACK_WEB_URL
        }/solutions/${solutionId}/attachments`
      )
    ).pipe(
      tap((res) => {
        this.solutionStore.update(solutionId, (solution) => {
          return {
            ...solution,
            attachments: res.data.attachments,
          };
        });
      }),
      map((res) => {
        return solutionQuery.getEntity(solutionId);
      })
    );
  }

  postAttachmentToSolution(
    solutionId: ID,
    files: File[]
  ): Observable<Solution> {
    const formData = new FormData();
    formData.append('file', files[0]);

    return from(
      axios.post(
        `${
          (window as any).env.REACT_APP_BACK_WEB_URL
        }/solutions/${solutionId}/attachments`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      )
    ).pipe(
      tap((res) => {
        this.solutionStore.update(solutionId, (solution) => {
          return {
            ...solution,
            ...res.data,
          };
        });
        this.toggleUploadingDocument();
      }),
      map((res) => {
        return solutionQuery.getEntity(solutionId);
      })
    );
  }

  deleteSolutionAttachment(
    solutionId: ID,
    attachmentId: ID
  ): Observable<Solution> {
    return from(
      axios.delete(
        `${
          (window as any).env.REACT_APP_BACK_WEB_URL
        }/solutions/${solutionId}/attachments/${attachmentId}`
      )
    ).pipe(
      tap((res) => {
        this.solutionStore.update(solutionId, (solution) => {
          return {
            ...solution,
            ...res.data,
          };
        });
      }),
      map((res) => {
        return solutionQuery.getEntity(solutionId);
      })
    );
  }

  //   _____ _                   _          _
  //  /  ___| |                 | |        | |
  //  \ `--.| |_ ___  _ __ ___  | |__   ___| |_ __   ___ _ __ ___
  //   `--. \ __/ _ \| '__/ _ \ | '_ \ / _ \ | '_ \ / _ \ '__/ __|
  //  /\__/ / || (_) | | |  __/ | | | |  __/ | |_) |  __/ |  \__ \
  //  \____/ \__\___/|_|  \___| |_| |_|\___|_| .__/ \___|_|  |___/
  // 										| |
  // 										|_|

  toggleUploadingDocument(): Observable<Capsule> {
    return this.solutionStore.update((state) => ({
      ...state,
      ui: {
        ...state.ui,
        isUploadingDocument: !state.ui.isUploadingDocument,
      },
    }));
  }

  // postAttachmentToExistingIdea(ideaId: ID, attachment: File) {
  // 	const ideaFormData = new FormData()
  // 	ideaFormData.append('file', attachment)
  // 	return fromRequest(
  // 		axios.post(`${(window as any).env.REACT_APP_BACK_WEB_URL}/ideas/${ideaId}/attachments`, ideaFormData, {
  // 			headers: { 'Content-Type': undefined },
  // 		}),
  // 	).pipe(map(res => res.data))
  // }

  // updateIdea(ideaId: ID, description: string, tags: string[], attachments: File[]) {
  // 	const ideaFormData = new FormData()
  // 	ideaFormData.append('description', description)
  // 	ideaFormData.append('tags', tags.join(','))
  // 	attachments.forEach(attachment => {
  // 		ideaFormData.append('attachments[]', attachment)
  // 	})
  // 	return fromRequest(
  // 		axios.patch(`${(window as any).env.REACT_APP_BACK_WEB_URL}/ideas/${ideaId}`, ideaFormData, {
  // 			headers: { 'Content-Type': undefined },
  // 		}),
  // 	).pipe(
  // 		map(res => {
  // 			if (res) {
  // 				return res.data
  // 			}
  // 			return of(null)
  // 		}),
  // 	)
  // }

  // likeIdea(ideaId: ID) {
  // 	return fromRequest(axios.patch(`${(window as any).env.REACT_APP_BACK_WEB_URL}/ideas/${ideaId}/like`)).pipe(
  // 		map(res => res.data),
  // 	)
  // }

  // postIdeaComment(ideaId: ID, comment: string): Observable<any> {
  // 	return fromRequest(
  // 		axios.post(`${(window as any).env.REACT_APP_BACK_WEB_URL}/ideas/${ideaId}/comments`, {
  // 			text: comment,
  // 		}),
  // 	).pipe(map(res => res.data))
  // }

  // editIdeaComment(commentId: ID, editedComment: string) {
  // 	return fromRequest(
  // 		axios.patch(`${(window as any).env.REACT_APP_BACK_WEB_URL}/ideas/comments/${commentId}`, {
  // 			text: editedComment,
  // 		}),
  // 	).pipe(map(res => res.data))
  // }

  // deleteIdeaComment(commentId: ID) {
  // 	return fromRequest(axios.delete(`${(window as any).env.REACT_APP_BACK_WEB_URL}/ideas/comments/${commentId}`)).pipe(
  // 		map(res => res.data),
  // 	)
  // }

  // deleteIdea(ideaId: ID) {
  // 	return fromRequest(axios.delete(`${(window as any).env.REACT_APP_BACK_WEB_URL}/ideas/${ideaId}`))
  // }
}

export const solutionService = new SolutionService(solutionStore);

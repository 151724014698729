import { modalStore } from './modal.store';
import { EntityState } from '@datorama/akita';

export enum ModalEnum {
  FEEDBACK = 'feedback',
  SHARE_TO_CONSTELLATIONS = 'shareToConstellations',
  SHARE_TO_USERS = 'shareToUsers',
  INVITE_EXPERT = 'inviteExpert',
  MESSAGE_SERVICE = 'messageService',
  DELETE_ACCOUNT = 'deleteAccount',
  CLOSE_MODAL = 'closeModal',
  REMOVE_VALIDATOR_MODAL = 'removeValidatorModal',
  SELECT_EXPERT_TABLE_COLUMNS = 'selectExpertTableColumns',
  INVITE_USER_MODAL = 'inviteUserModal',
  SHOW_COLLABORATOR = 'showCollaborator', // ici je crée le modal pour afficher le collaborateur sélectionné
  CREATE_CONSTELLATION = 'createConstellation',
  CREATE_CAPSULE = 'createCaspule',
  FIRST_LOGIN = 'firstLogin',
  INVITE_TO_CONSTELLATION = 'inviteToConstellation',
  CREATE_POLL = 'createPoll',
  EDIT_POLL = 'editPoll',
  CHANGE_PILOTE = 'changePilote',
  ACTIVATE_CAPSULE = 'activateCapsule',
  ADVANCEMENT_CAPSULE = 'advancementCapsule',
  ADVANCEMENT_FINISHED = 'advancementFinished',
  SHARE_FEEDBACK = 'shareFeedback',
  JOIN_LEAVE_CONSTELLATION = 'joinLeaveConstellation',
  CHOOSE_CONSTELLATIONS = 'chooseConstellations',
  ADD_SOLUTION = 'addSolution',
  UPLOAD_IMAGE = 'uploadImage',
}

export interface ModalState<T = any> extends EntityState<ModalModel> {
  type?: ModalEnum | null;
  open?: boolean;
  params?: ModalParams<T>;
}

export interface ModalModel {}
export type ModalParams<T> = {
  id?: string | number;
  message?: { content: string; type: 'error' | 'success' };
} & (T extends undefined ? {} : { payload: T });

type OpenModalFn = <T>(
  type: ModalEnum,
  params?: T extends undefined ? ModalParams<T> | undefined : ModalParams<T>
) => void;

export const openModal: OpenModalFn = (type, params) =>
  modalStore.open(type, params);

export const openErrorMessage = (content: string) =>
  modalStore.open(ModalEnum.MESSAGE_SERVICE, {
    message: { type: 'error', content },
  });
export const openSuccessMessage = (content: string) =>
  modalStore.open(ModalEnum.MESSAGE_SERVICE, {
    message: { type: 'success', content },
  });
export type MessageParams = { content: string; type: 'error' | 'success' };

export const openSnackBar = (message: MessageParams) =>
  modalStore.open(ModalEnum.MESSAGE_SERVICE, {
    message,
  });

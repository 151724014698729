import { capsuleStore as store, CapsuleStore } from '../capsule.store';
import { Capsule } from '../capsule.model';
import { from, Observable, take } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { post } from '../../../utils/axios';
import {
  AssignOrUnassignCriteriaResponseDto,
  AssignOrUnassignDto,
} from '@zdlibs/shared/dto';
import { LabelCriteria, LabelCriteriaId } from '@zdlibs/domain/label';
import { capsuleLabelQuery, CapsuleLabelQuery } from './capsule-label.query';

export class CapsuleLabelService {
  constructor(
    private capsuleStore: CapsuleStore,
    private queryCapsuleLabel: CapsuleLabelQuery
  ) {}

  assignOrUnAssignLabelCriteria(id: Capsule['id'], body: AssignOrUnassignDto) {
    return from(
      post<AssignOrUnassignDto, AssignOrUnassignCriteriaResponseDto>(
        'capsules/' + id + '/criteria',
        body
      )
    ).pipe(
      map((res) => {
        if (!res.data.criteria) {
          return this.capsuleStore.unAssignLabelCriteria(id, body.labelId);
        }
        return this.capsuleStore.assignLabelCriteria(id, res.data);
      }),
      take(1)
    );
  }

  updateCapsuleLabelCriteria(
    criteriaId: LabelCriteriaId,
    updatedLabelCriteria: LabelCriteria
  ) {
    return this.queryCapsuleLabel.getCapsuleByCriteria(criteriaId).pipe(
      tap((capsules) =>
        this.capsuleStore.updateCriteria(capsules, updatedLabelCriteria)
      ),
      take(1)
    );
  }

  removeCapsuleLabelCriteria(criteriaId: LabelCriteriaId) {
    return this.queryCapsuleLabel.getCapsuleByCriteria(criteriaId).pipe(
      tap((capsules) => this.capsuleStore.removeCriteria(capsules, criteriaId)),
      take(1)
    );
  }
}
export const capsuleLabelService = new CapsuleLabelService(
  store,
  capsuleLabelQuery
);

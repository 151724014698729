import {
  ActiveState,
  EntityState,
  EntityStore,
  ID,
  StoreConfig,
} from '@datorama/akita';

import { Tag } from './tag.model';

// export type TagState = EntityState<Tag>

export interface TagState extends EntityState<Tag>, ActiveState {
  active: ID;
  ui: {
    isEditableTagListModalOpen: boolean;
  };
}
export const initialTagState: TagState = {
  active: null,
  ui: {
    isEditableTagListModalOpen: false,
  },
};

@StoreConfig({
  name: 'tag',
})
export class TagStore extends EntityStore<TagState> {
  constructor() {
    super(initialTagState);
  }
}

export const tagStore = new TagStore();

import { ID } from '@datorama/akita';
import axios from 'axios';
import { from, Observable, take } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { UserNotification } from './notification.model';
import { userNotificationQuery } from './notification.query';
import {
  UserNotificationStore,
  userNotificationStore,
} from './notification.store';

export class UserNotificationService {
  constructor(private notificationStore: UserNotificationStore) {}

  getNotifications(): Observable<UserNotification[]> {
    return from(
      axios.get((window as any).env.REACT_APP_BACK_WEB_URL + '/notification')
    ).pipe(
      tap((res) => {
        this.notificationStore.set(res.data);
      }),
      map((res) => res.data),
      take(1)
    );
  }

  patchNotification(
    notificationId: ID,
    updateNotification: Partial<UserNotification>
  ): Observable<UserNotification> {
    return from(
      axios.patch(
        `${
          (window as any).env.REACT_APP_BACK_WEB_URL
        }/notification/${notificationId}`,
        updateNotification
      )
    ).pipe(
      tap((res) => {
        this.notificationStore.update(notificationId, (notification) => {
          return {
            ...notification,
            ...res.data,
          };
        });
      }),
      map((res) => {
        return userNotificationQuery.getEntity(res.data.id);
      })
    );
  }
  delete(notificationId: ID): Observable<UserNotification> {
    return from(
      axios.delete(
        `${
          (window as any).env.REACT_APP_BACK_WEB_URL
        }/notification/${notificationId}`
      )
    ).pipe(
      tap((res) => {
        this.notificationStore.remove(notificationId);
      }),
      map((res) => {
        return userNotificationQuery.getEntity(res.data.id);
      })
    );
  }

  deleteAll(): Observable<UserNotification> {
    return from(
      axios.delete(`${(window as any).env.REACT_APP_BACK_WEB_URL}/notification`)
    ).pipe(
      tap((res) => {
        this.notificationStore.reset();
      }),
      map((res) => {
        return userNotificationQuery.getEntity(res.data.id);
      })
    );
  }
}

export const userNotificationService = new UserNotificationService(
  userNotificationStore
);

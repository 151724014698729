import { QueryEntity } from '@datorama/akita';

import {
  UserNotificationState,
  UserNotificationStore,
  userNotificationStore,
} from './notification.store';

export class UserNotificationQuery extends QueryEntity<UserNotificationState> {
  constructor(protected store: UserNotificationStore) {
    super(store);
  }

  notifications$ = this.selectAll();
}

export const userNotificationQuery = new UserNotificationQuery(
  userNotificationStore
);

import React from 'react';
import { makeStyles } from '@material-ui/core';
import { getRoundLogoPath } from '../../../utils/env';

interface RoundLogoProps {
  hasCompanyLogo?: boolean;
}

const useStyles = makeStyles(() => ({
  root: ({ hasCompanyLogo }: RoundLogoProps) => ({
    maxWidth: hasCompanyLogo ? 150 : 120,
    width: '60%',
    paddingTop: hasCompanyLogo ? 0 : 40,
    paddingBottom: hasCompanyLogo ? 0 : 40,
  }),
}));

const RoundLogo = ({ hasCompanyLogo }: RoundLogoProps) => {
  const classes = useStyles({ hasCompanyLogo });

  return (
    <img className={classes.root} src={getRoundLogoPath()} alt="Round Logo" />
  );
};

RoundLogo.defaultProps = {
  hasCompanyLogo: false,
};

export default RoundLogo;

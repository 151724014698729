import { CapsuleDetails } from '@store/capsule/capsule.service';
import { Capsule } from '../../capsule.model';
import { dateFromNow } from '../../../../utils/date';
import { truncate } from '../../../../utils/truncate';
import {
  isInProgress,
  isNew,
  isNotResolved,
  isPending,
  isResolved,
} from '../../capsule-status/capsule-status.model';
import { isPiloteDifferentCreator } from '@zdlibs/domain/user';
import { Solution } from '@components/atoms/Icons/Solution/Solution';
import { Poll } from '@components/atoms/Icons/Poll/Poll';
import { Messages } from '@components/atoms/Icons/Messages/Messages';
import { Subscribers } from '@components/atoms/Icons/Subscribers';
import { Feedbacks } from '@components/atoms/Icons/Feedbacks';

const getInfo = (capsule: Capsule, isMobile: boolean) => {
  return {
    createdAtToDisplay: dateFromNow(capsule.createdAt, true),
    createdBy: truncate(
      `${capsule.createdBy.firstName} ${capsule.createdBy.lastName}`,
      isMobile ? 20 : 100,
      !isMobile
    ),
    assignedTo: truncate(
      `${capsule.assignedTo?.firstName} ${capsule.assignedTo?.lastName}`,
      isMobile ? 20 : 100,
      !isMobile
    ),
    activatedBy: capsule.activatedBy
      ? 'par ' +
        truncate(
          `${capsule.activatedBy?.firstName} ${capsule.activatedBy?.lastName}`,
          isMobile ? 20 : 100,
          !isMobile
        )
      : '',
    resolvedBy: capsule.resolvedBy
      ? 'par ' +
        truncate(
          `${capsule.resolvedBy?.firstName} ${capsule.resolvedBy?.lastName}`,
          isMobile ? 20 : 100,
          !isMobile
        )
      : '',
    activatedAtToDisplay: dateFromNow(capsule.activatedAt),
    endedAt: dateFromNow(capsule.endedAt, true),
  };
};

export const getCaptionListItemText = (capsule: Capsule, isMobile: boolean) => {
  const info = getInfo(capsule, isMobile);

  let caption = `Créée par ${info.createdBy} ${info.createdAtToDisplay} `;
  if (isNew(capsule) && isPiloteDifferentCreator(capsule))
    caption = `${caption} / Pilotée par ${info.assignedTo}`;
  if (isPending(capsule) && isPiloteDifferentCreator(capsule))
    caption = `${caption} / Pilotée par ${info.assignedTo}`;
  //activatedBy est modifié en back en fonction de la phase de vie de la capsule
  if (isInProgress(capsule))
    caption = `${caption} / Activée ${info.activatedBy} ${info.activatedAtToDisplay}`;
  if (isResolved(capsule))
    caption = `${caption} / Réalisé ${info.resolvedBy} ${info.endedAt}`;
  if (isNotResolved(capsule))
    caption = `${caption} / Clôturé ${info.activatedBy} ${info.endedAt}`;
  return caption;
};

export const nbFeedbacks = (capsule: Capsule) => capsule.nbRatings || 0;
export const nbMessages = (capsule: Capsule) => capsule.nbComments || 0;
export const nbUsersParticipating = (capsule: Capsule) =>
  capsule.nbUsersParticipating || 0;
export const nbIdeas = (capsule: CapsuleDetails) =>
  capsule.solutions?.length || 0;
export const nbSolutions = (capsule: CapsuleDetails) =>
  (capsule.solutions && capsule.solutions.filter((s) => s.activated).length) ||
  0;
export const nbIdeasAndSolutions = (capsule: Capsule) => capsule.nbSolutions;
export const nbSondages = (capsule: Capsule) => capsule.nbPolls || 0;
export const nbExperts = (capsule: Capsule) => capsule.validators?.length || 0;

export const nbSondagesOrFeedbacks = (capsule: Capsule, isMobile: boolean) =>
  isResolved(capsule) ? (
    isMobile ? (
      <>
        {nbFeedbacks(capsule)} <Feedbacks />
      </>
    ) : (
      nbFeedbacks(capsule) + ' feedback(s)'
    )
  ) : isMobile ? (
    <>
      {nbSondages(capsule)} <Poll />
    </>
  ) : (
    nbSondages(capsule) + ' sondage(s)'
  );

export const capsuleNbItems = (capsule: Capsule, isMobile?: boolean) => {
  return [
    {
      text: isMobile ? (
        <>
          {nbUsersParticipating(capsule)} <Subscribers />
        </>
      ) : (
        nbUsersParticipating(capsule) + ' participant(s)'
      ),
    },
    {
      text: isMobile ? (
        <>
          {nbMessages(capsule)} <Messages />
        </>
      ) : (
        nbMessages(capsule) + ' message(s)'
      ),
    },
    {
      text: isMobile ? (
        <>
          {nbIdeasAndSolutions(capsule)} <Solution />
        </>
      ) : (
        nbIdeasAndSolutions(capsule) + ' solution(s)'
      ),
    },
    {
      text: nbSondagesOrFeedbacks(capsule, isMobile),
    },
  ];
};

import {
  filterCapsulesByDateRangeAndStatus,
  getOldXDays,
  isAfter45DaysOld,
  isBeetween15And30DaysOld,
  isBeetween30And45DaysOld,
} from '../../capsule/capsule-date/capsule-date.model';
import { getMs } from '../../../utils/date';
import { Capsule } from '../../capsule/capsule.model';
import { Search } from '../search.model';
import * as R from 'ramda';

const filterAndMerge =
  (caps: Capsule[], orignalCapsules: Capsule[]) =>
  (filter: Predicate<Capsule>): Capsule[] =>
    R.union(caps, R.filter(filter)(orignalCapsules));

export const filterFraicheur = (
  capsules: Capsule[],
  search: Search
): Capsule[] => {
  if (
    !search.showOld15Days &&
    !search.showOld30Days &&
    !search.showOld45Days &&
    !search.showOldBydate.startDate
  )
    return capsules;
  const orignalCapsules = capsules;

  capsules = [];
  if (search.showOldBydate.startDate && search.showOldBydate.endDate) {
    capsules = filterCapsulesByDateRangeAndStatus(
      orignalCapsules,
      search.showOldBydate.startDate,
      search.showOldBydate.endDate,
      search.showOldBydate.status
    );
  }

  if (search.showOld15Days === true) {
    const oldXDays = getOldXDays(new Date());
    capsules = filterAndMerge(
      capsules,
      orignalCapsules
    )((c) =>
      isBeetween15And30DaysOld(getMs(c.createdAt), oldXDays(15), oldXDays(30))
    );
  }
  if (search.showOld30Days === true) {
    const oldXDays = getOldXDays(new Date());
    capsules = filterAndMerge(
      capsules,
      orignalCapsules
    )((c) =>
      isBeetween30And45DaysOld(getMs(c.createdAt), oldXDays(30), oldXDays(45))
    );
  }
  if (search.showOld45Days === true) {
    const oldXDays = getOldXDays(new Date());
    capsules = filterAndMerge(
      capsules,
      orignalCapsules
    )((c) => isAfter45DaysOld(getMs(c.createdAt), oldXDays(45)));
  }
  return capsules;
};

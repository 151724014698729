import { QueryEntity } from '@datorama/akita';

import { SearchState, SearchStore, searchStore } from './search.store';
import { companyQuery } from '../company/company.query';
import { mergeMap, Observable, of } from 'rxjs';
import { Search } from './search.model';
import { Constellation } from '../constellation/constellation.model';
import {
  ConstellationQuery,
  constellationQuery as constellatQuery,
} from '../constellation/constellation.query';
import { map } from 'rxjs/operators';

export class SearchQuery extends QueryEntity<SearchState> {
  constructor(
    protected store: SearchStore,
    private constellationQuery: ConstellationQuery
  ) {
    super(store);
  }

  currentSearch$ = this.select((state) => state.search);
  getSearch(): Observable<Search> {
    return this.select((state) => state.search);
  }
  position$ = companyQuery.isValidationWorkFlowEnabled().pipe(
    mergeMap((isWorkflowValidation) => {
      if (isWorkflowValidation)
        return this.select((state) => state.filters?.position || []);
      return of(false);
    })
  );
  companySearches$ = this.selectAll();

  getSelectedTags() {
    return this.select((state) => state.search.categories.tags || []);
  }
  getSelectedDomainAndThematic() {
    return this.select(
      (state) => state.search.categories.domainAndThematic || []
    );
  }
  selectConstellationsBySearchTerm(): Observable<Constellation[]> {
    return this.getSearch().pipe(
      map((search) => search.searchTerm),
      mergeMap((searchTerm) =>
        this.constellationQuery.selectAll({
          filterBy: (c) =>
            c.name.toLowerCase().includes(searchTerm) ||
            c.description?.toLowerCase().includes(searchTerm) ||
            c.tags?.some((tag) => tag.text.toLowerCase().includes(searchTerm)),
        })
      )
    );
  }
}

export const searchQuery = new SearchQuery(searchStore, constellatQuery);

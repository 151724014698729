import { CategoryState, categoryStore, CategoryStore } from './categoryStore';
import { forkJoin, from, Observable, of } from 'rxjs';
import axios from 'axios';
import { catchError, map, tap } from 'rxjs/operators';
import { categoryQuery } from './category.query';
import { ID } from '@datorama/akita';
import { modalStore } from '../modal/modal.store';
import { ModalEnum } from '../modal/modal.model';
import { tagStore } from '../tag/tag.store';
import { tagService } from '../tag/tag.service';
import {
  Category,
  CategoryCreateParams,
  CategoryType,
  CategoryUpdate,
} from '@zdlibs/domain-category';

const endPointCategory =
  (window as any).env.REACT_APP_BACK_WEB_URL + '/tags/categories';
const endPointCategoryConfig =
  (window as any).env.REACT_APP_BACK_WEB_URL + '/tags/categories-config';
const tagEndPoint = (window as any).env.REACT_APP_BACK_WEB_URL + '/tags';

export class CategoryService {
  constructor(private categoryStore: CategoryStore) {}

  getCategoriesAndTags(): Observable<CategoryState> {
    return forkJoin([
      from(axios.get(endPointCategoryConfig)),
      from(axios.get(endPointCategory)),
      from(axios.get(tagEndPoint)),
    ]).pipe(
      map(([configRes, catRes, tagRes]) => {
        const isDomainCategoriesEnabled =
          configRes.data.domainCategoriesEnabled;
        const isThematicCategoriesEnabled =
          configRes.data.thematicCategoriesEnabled;
        const categories = catRes.data.categories;
        const tags = tagRes.data;

        this.categoryStore.set(categories);
        tagStore.set(tags);

        return {
          tags,
          categories,
          isDomainCategoriesEnabled,
          isThematicCategoriesEnabled,
        };
      }),
      map(
        ({
          tags,
          categories,
          isDomainCategoriesEnabled,
          isThematicCategoriesEnabled,
        }) => {
          const tagTable = {
            domain:
              isDomainCategoriesEnabled &&
              categories.filter((c) => c.type === 'domain'),
            thematic:
              !isDomainCategoriesEnabled &&
              isThematicCategoriesEnabled &&
              categories.filter((c) => c.type === 'thematic'),
            tag:
              !isDomainCategoriesEnabled &&
              !isThematicCategoriesEnabled &&
              tags,
          };
          const newState = {
            isDomainCategoriesEnabled,
            isThematicCategoriesEnabled,
            ui: {
              tagTable,
            },
          };
          this.categoryStore.update(newState);
          return newState;
        }
      ),
      map((newState) => {
        return newState;
      })
    );
  }
  getCategoryConfig() {
    return from(axios.get(endPointCategoryConfig)).pipe(
      tap((res) => {
        return this.categoryStore.update({
          isDomainCategoriesEnabled: res.data.domainCategoriesEnabled,
          isThematicCategoriesEnabled: res.data.thematicCategoriesEnabled,
        });
      }),
      map((res) => res.data)
    );
  }

  createCategory(categoryParams: CategoryCreateParams): Observable<Category> {
    return from(axios.post(endPointCategory, categoryParams)).pipe(
      tap((res) => {
        this.categoryStore.add(res.data);
      }),
      tap((res) => {
        categoryQuery.addTagTableEntity(res.data, categoryParams.type);
      }),
      map((res) => {
        return res.data;
      })
    );
  }
  switchDomain(activate: boolean) {
    const activateEndpoint = activate ? '/enable-type' : '/disable-type';
    return from(
      axios.post(endPointCategoryConfig + activateEndpoint, { type: 'domain' })
    )
      .pipe(
        tap(() => {
          this.categoryStore.update({ isThematicCategoriesEnabled: activate });
        })
      )
      .subscribe();
  }
  switchThematic(activate: boolean) {
    const activateEndpoint = activate ? '/enable-type' : '/disable-type';
    return from(
      axios.post(endPointCategoryConfig + activateEndpoint, {
        type: 'thematic',
      })
    )
      .pipe(
        tap(() => {
          this.categoryStore.update({ isThematicCategoriesEnabled: activate });
        })
      )
      .subscribe();
  }

  patchCategory(
    categoryId: Category['id'],
    category: CategoryUpdate
  ): Observable<Category> {
    return from(
      axios.patch(endPointCategory + `/${categoryId}`, category)
    ).pipe(
      tap((res) => {
        this.categoryStore.update(categoryId, (cat) => ({
          ...cat,
          name: res.data.newName,
        }));
        categoryQuery.updateTagTableEntity(categoryId, category);
      }),
      map((res) => {
        return res.data;
      })
    );
  }

  deleteCategory(categoryId: ID, type: CategoryType) {
    return from(axios.delete(endPointCategory + `/${categoryId}`)).pipe(
      tap((res) => {
        categoryStore.remove(categoryId);
        tagService.getTags().subscribe((res) => console.log(res));

        categoryQuery.deleteTagTableEntity(categoryId, type);
        modalStore.open(ModalEnum.MESSAGE_SERVICE, {
          message: {
            content: 'La catégorie a été supprimée',
            type: 'success',
          },
        });
        return true;
      }),
      catchError((err) => {
        modalStore.open(ModalEnum.MESSAGE_SERVICE, {
          message: {
            content: 'Vous ne pouvez pas supprimer cette catégorie',
            type: 'error',
          },
        });
        return of(false);
      })
    );
  }
}

export const categoryService = new CategoryService(categoryStore);

import React from 'react';

import IconButtonView from './IconButton.view';
import NotificationButtonView from './NotificationButton.view';
import PrimaryButtonView from './PrimaryButton.view';
import SecondaryButtonView from './SecondaryButton.view';
import { IconButtonProps } from './IconButton.view';
import { ButtonProps } from '@material-ui/core/Button/Button';

export interface CustomButtonProps extends ButtonProps {
  customStyle?: 'small';
  isLoading?: boolean;
  borderColor?: string;
  title?: string;
}

export const CustomIconButton = (props: IconButtonProps) => (
  <IconButtonView {...props} />
);
export const NotificationButton = (props) => (
  <NotificationButtonView {...props} />
);
export const PrimaryButton = (props: CustomButtonProps) => (
  <PrimaryButtonView {...props} />
);
export const SecondaryButton = (props: CustomButtonProps) => (
  <SecondaryButtonView {...props} />
);

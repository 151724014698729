import { ID } from '@datorama/akita';
import { Constellation } from '@store/constellation/constellation.model';
import axios from 'axios';
import { from, Observable, of, take } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { normalizeNames } from '../../utils/normalizeNames';
import { categoryQuery } from '../category/category.query';
import { ModalEnum } from '../modal/modal.model';
import { modalStore } from '../modal/modal.store';

import { CreateTag } from './create-tag.model';
import { Tag } from './tag.model';
import { tagQuery } from './tag.query';
import { TagStore, tagStore } from './tag.store';
import {
  constellationStore,
  ConstellationStore,
} from '../constellation/constellation.store';
import { capsuleService } from '@store/capsule/capsule.service';

export class TagService {
  constructor(
    private tagStore: TagStore,
    private constellationStore: ConstellationStore
  ) {}

  getTags(): Observable<Tag[]> {
    return from(
      axios.get((window as any).env.REACT_APP_BACK_WEB_URL + '/tags')
    ).pipe(
      tap((res) => {
        res.data = res.data.map((tag) => ({
          ...tag,
          text: normalizeNames(tag.text),
        }));
        this.tagStore.set(res.data);
      }),
      map((res) => res.data),
      take(1)
    );
  }

  createTag(tag: CreateTag): Observable<Tag> {
    return from(
      axios.post((window as any).env.REACT_APP_BACK_WEB_URL + '/tags', tag)
    ).pipe(
      tap((res) => {
        this.tagStore.add(res.data);
        categoryQuery.addTagTableEntity(res.data, 'tag');
      }),
      map((res) => res.data)
    );
  }

  patchTag(tagId: ID, updateTag: Tag): Observable<Tag> {
    return from(
      axios.patch(
        `${(window as any).env.REACT_APP_BACK_WEB_URL}/tags/${tagId}`,
        updateTag
      )
    ).pipe(
      tap((res) => {
        this.tagStore.update(tagId, (tag) => {
          return {
            ...tag,
            ...res.data,
          };
        });
        categoryQuery.updateTagTableEntity(tagId, {
          newName: updateTag.text,
          type: 'tag',
        });
      }),
      map((res) => {
        return tagQuery.getEntity(res.data.id);
      })
    );
  }

  deleteTag(tagId: ID) {
    return from(
      axios.delete(
        `${(window as any).env.REACT_APP_BACK_WEB_URL}/tags/${tagId}`
      )
    ).pipe(
      tap(() => {
        tagStore.remove(tagId);
        categoryQuery.deleteTagTableEntity(tagId, 'tag');
        modalStore.open(ModalEnum.MESSAGE_SERVICE, {
          message: {
            content: 'La catégorie a été supprimée',
            type: 'success',
          },
        });
        return true;
      }),
      catchError((err) => {
        modalStore.open(ModalEnum.MESSAGE_SERVICE, {
          message: {
            content:
              "Vous ne pouvez pas supprimer cette catégorie, vérifiez si personne d'autre n'est validateur",
            type: 'error',
          },
        });
        return of(false);
      })
    );
  }

  toggleEditableTagListModalOpen(): Observable<Tag> {
    return this.tagStore.update((state) => ({
      ...state,
      ui: {
        ...state.ui,
        isEditableTagListModalOpen: !state.ui.isEditableTagListModalOpen,
      },
    }));
  }

  assocTagToConstellation(
    tagId: ID,
    constellationId: Constellation['id']
  ): Observable<Tag> {
    return from(
      axios.post<Tag>(
        `${
          (window as any).env.REACT_APP_BACK_WEB_URL
        }/tags/${tagId}/assocTagToConstellation`,
        { constellationId }
      )
    ).pipe(
      tap((res) => {
        this.tagStore.update(tagId, (tag) => {
          return {
            ...tag,
            ...res.data,
          };
        });
      }),
      tap((res) => {
        this.constellationStore.update(constellationId, (constellation) => {
          return {
            ...constellation,
            tags: [...constellation.tags, res.data],
          };
        });
      }),
      tap(() => {
        capsuleService.updateAllCapsules().subscribe();
      }),
      map((_res) => {
        return tagQuery.getEntity(tagId);
      }),
      take(1)
    );
  }

  removeAssocTagToConstellation(
    tagId: ID,
    constellationId: Constellation['id']
  ): Observable<Tag> {
    return from(
      axios.post<Tag>(
        `${
          (window as any).env.REACT_APP_BACK_WEB_URL
        }/tags/${tagId}/removeAssocTagToConstellation`,
        { constellationId }
      )
    ).pipe(
      tap((res) => {
        this.tagStore.update(tagId, (tag) => {
          return {
            ...tag,
            ...res.data,
          };
        });
      }),
      tap((_res) => {
        this.constellationStore.update(constellationId, (constellation) => {
          return {
            ...constellation,
            tags: constellation.tags?.filter((tag) => tag.id !== tagId) ?? [],
          };
        });
      }),
      tap(() => {
        capsuleService.updateAllCapsules().subscribe();
      }),
      map((_res) => {
        return tagQuery.getEntity(tagId);
      }),
      take(1)
    );
  }
}

export const tagService = new TagService(tagStore, constellationStore);

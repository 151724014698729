import axios from 'axios';
import { from, Observable, take } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { sessionService } from '../session/session.service';
import { Company } from './company.model';
import { companyQuery } from './company.query';
import { CompanyStore, companyStore } from './company.store';

import { fromRequest } from '../../utils/utils';
import { patch } from '../../utils/axios';
import { CompanyDto, IsLabelControlledDtoRequest } from '@zdlibs/shared/dto';

export class CompanyService {
  constructor(private companyStore: CompanyStore) {}

  patchValidationFlow(
    isValidationFlowControlled: boolean
  ): Observable<Company> {
    const companyId = sessionService.currentUser().companyId;
    return from(
      axios.patch(
        `${
          (window as any).env.REACT_APP_BACK_WEB_URL
        }/companies/validationflow/${
          isValidationFlowControlled ? 'enable' : 'disable'
        }`
      )
    ).pipe(
      tap((res) => {
        // console.log(res.data)
        this.companyStore.update(companyId, (company) => {
          return {
            ...company,
            ...res.data,
          };
        });
      }),
      map(() => {
        return companyQuery.getEntity(companyId);
      }),
      take(1)
    );
  }

  patchIsLabelControlled(isLabelControlled: boolean): Observable<Company> {
    const companyId = sessionService.currentUser().companyId;
    return from(
      patch<IsLabelControlledDtoRequest, CompanyDto>(
        `companies/labelControlled`,
        { isLabelControlled }
      )
    ).pipe(
      tap((res) => {
        // console.log(res.data)
        this.companyStore.update(companyId, (company) => {
          return {
            ...company,
            ...res.data,
          };
        });
      }),
      map(() => {
        return companyQuery.getEntity(companyId);
      }),
      take(1)
    );
  }

  getCompany(): Observable<Company> {
    const companyId = sessionService.currentUser().companyId;
    return fromRequest(
      axios.get(
        `${(window as any).env.REACT_APP_BACK_WEB_URL}/companies/${companyId}`
      )
    ).pipe(
      tap((res) => {
        this.companyStore.add(res.data);
      }),
      map((res) => {
        return companyQuery.getEntity(companyId);
      })
    );
  }

  // getCompanies(): Observable<Company> {
  // 	return fromRequest(axios.get(`${(window as any).env.REACT_APP_BACK_WEB_URL}/companies`)).pipe(
  // 		tap(res => {
  // 			this.companyStore.add(res.data)
  // 		}),
  // 		map(res => res.data),
  // 	)
  // }

  // getCompanyUsers(companyId: ID): Observable<User[]> {
  // 	return fromRequest(axios.get(`${(window as any).env.REACT_APP_BACK_WEB_URL}/companies/${companyId}/users`)).pipe(
  // 		map(res => res.data),
  // 	)
  // }
}

export const companyService = new CompanyService(companyStore);

import React from 'react';
import Button from '@material-ui/core/Button';
import { useButtonStyles } from './buttons.style';
import { CustomButtonProps } from './Buttons';

const PrimaryButton = (props: CustomButtonProps) => {
  const classes = useButtonStyles(props);
  const { isLoading, onClick, disabled, borderColor, customStyle, ...rest } =
    props;
  if (isLoading) return <>En chargement...</>;
  //TODO disabled UI
  return (
    <Button
      color="primary"
      className={classes.root}
      onClick={onClick}
      disabled={disabled}
      title={props.title}
      {...rest}
    >
      {props.children}
    </Button>
  );
};

export default PrimaryButton;

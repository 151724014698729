import socketio from 'socket.io-client';

export const connection = (token) => {
  const socket = socketio.connect(window.env.REACT_APP_API_SOCKET_URL, {
    reconnection: true,
    reconnectionDelay: 2000, //starts with 2 secs delay, then 4, 6, 8, until 60 where it stays forever until it reconnects
    reconnectionDelayMax: 60000, //1 minute maximum delay between connections
    reconnectionAttempts: 3, //to prevent dead clients, having the user to having to manually reconnect after a server restart.
    timeout: 10000, //before connect_error and connect_timeout are emitted.
    transports: ['websocket', 'polling'],
    query: { token }, //forces the transport to be only websocket. Server needs to be setup as well/
  });

  socket.on('connect_error', (error) => {
    console.log('connect_error', error);
  });

  socket.on('error', function (err) {
    console.log(err);
    if (err === 'Invalid namespace') {
      console.warn('Attempted to connect to invalid namespace');
    } else {
      console.warn('Error on socket.io client', err);
    }
  });

  return socket;
};

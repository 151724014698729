import { EntityStore, StoreConfig } from '@datorama/akita';
import { ModalEnum, ModalParams, ModalState } from './modal.model';

export const initialModalState: ModalState = {};

@StoreConfig({
  name: 'modal',
})
export class ModalStore extends EntityStore<ModalState> {
  constructor() {
    super(initialModalState);
  }
  open<T = undefined>(type: ModalEnum, params: ModalParams<T>) {
    this.update({ type, open: true, params });
  }
  close() {
    this.update({ type: null, open: false });
  }
}

export const modalStore = new ModalStore();

import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React from 'react';

export const Solution = (props: SvgIconProps) => {
  return (
    <SvgIcon
      viewBox="0 0 21 23"
      xmlns="http://www.w3.org/2000/svg"
      fill="#0063C6"
      {...props}
    >
      <g clip-path="url(#clip0_1_5)">
        <path d="M16.4904 5.05884L17.6225 3.92668C17.8725 3.67675 17.8725 3.27541 17.6225 3.02173C17.3726 2.77179 16.9712 2.77179 16.7175 3.02173L15.5854 4.15388C15.3354 4.40382 15.3354 4.80516 15.5854 5.0589C15.8353 5.30871 16.2404 5.30871 16.4904 5.05884Z" />
        <path d="M16.4904 15.593C16.2404 15.343 15.8391 15.343 15.5854 15.593C15.3354 15.8429 15.3354 16.2442 15.5854 16.498L16.7175 17.6301C16.9674 17.8801 17.3688 17.8801 17.6225 17.6301C17.8725 17.3802 17.8725 16.9789 17.6225 16.7251L16.4904 15.593Z" />
        <path d="M4.15383 5.05884C4.40376 5.30877 4.8051 5.30877 5.05884 5.05884C5.30877 4.80891 5.30877 4.40756 5.05884 4.15383L3.92662 3.02167C3.67669 2.77174 3.27535 2.77174 3.02161 3.02167C2.76793 3.2716 2.77168 3.67294 3.02161 3.92662L4.15383 5.05884Z" />
        <path d="M16.301 9.44362C15.9375 6.36894 13.2036 4.27876 10.322 4.27876C6.98987 4.27876 4.2787 6.98993 4.2787 10.3258C4.2787 12.2343 5.15337 13.9912 6.67938 15.1461C7.3723 15.6725 7.76614 16.4449 7.76614 17.2779V18.1564C7.74342 18.5199 8.1372 18.8796 8.54616 18.7812L12.3857 17.9027C12.6735 17.8346 12.8818 17.5771 12.8818 17.2779C12.8818 16.4487 13.2908 15.6649 13.995 15.1234C15.752 13.7792 16.6115 11.6587 16.301 9.44362ZM13.215 14.1124C12.3365 14.7864 11.7761 15.7331 11.636 16.763L9.03847 17.278C9.03847 16.0436 8.45912 14.8925 7.44434 14.1276C6.244 13.2188 5.55108 11.8329 5.55108 10.3259C5.55108 7.69802 7.68672 5.55864 10.3183 5.55864C12.5827 5.64574 14.5782 7.17169 15.0363 9.62162C15.2862 11.3748 14.6047 13.0522 13.215 14.1124Z" />
        <path d="M8.54241 20.879L12.3857 20.0005C12.7303 19.9248 12.9462 19.5802 12.8666 19.2356C12.7909 18.891 12.4463 18.679 12.1017 18.7547L8.25839 19.6332C7.9138 19.709 7.69796 20.0535 7.77751 20.3981C7.84188 20.7313 8.22055 20.9737 8.54241 20.879Z" />
        <path d="M12.1018 20.86L8.25845 21.7385C7.91387 21.8142 7.69802 22.1588 7.77757 22.5034C7.87222 22.8783 8.23198 23.0562 8.54247 22.9843L12.3858 22.1058C12.7304 22.0301 12.9462 21.6855 12.8667 21.3409C12.7871 20.9964 12.4463 20.7767 12.1018 20.86Z" />
        <path d="M10.3221 2.88156C10.6742 2.88156 10.962 2.5976 10.962 2.24165V0.639911C10.962 0.287769 10.678 0 10.3221 0C9.96995 0 9.68218 0.283962 9.68218 0.639911V2.23784C9.68212 2.59379 9.96995 2.88156 10.3221 2.88156Z" />
        <path d="M20.0043 9.68599H18.4063C18.0542 9.68599 17.7664 9.96995 17.7664 10.3259C17.7664 10.6819 18.0504 10.9658 18.4063 10.9658H20.0043C20.3564 10.9658 20.6442 10.6819 20.6442 10.3259C20.648 9.97376 20.3564 9.68599 20.0043 9.68599Z" />
        <path d="M2.23778 9.68599H0.639911C0.287769 9.68599 0 9.96995 0 10.3259C0 10.6819 0.283962 10.9658 0.639911 10.9658H2.23784C2.58998 10.9658 2.87775 10.6819 2.87775 10.3259C2.87775 9.97376 2.58998 9.68599 2.23778 9.68599Z" />
        <path d="M4.15382 15.593L3.02167 16.7251C2.77173 16.9751 2.77173 17.3764 3.02167 17.6301C3.2716 17.8801 3.67294 17.8801 3.92668 17.6301L5.05884 16.498C5.30877 16.2481 5.30877 15.8467 5.05884 15.593C4.80885 15.343 4.4037 15.343 4.15382 15.593Z" />
        <path d="M13.7716 9.80337C13.4649 8.10698 12.1283 6.89153 10.3221 6.83852C8.39856 6.83852 6.83091 8.40617 6.83091 10.3297C6.83091 11.4354 7.33452 12.4464 8.2168 13.1128C9.14074 13.8095 9.7882 14.7524 10.1025 15.8051L10.572 15.6952C10.9014 14.6842 11.5414 13.7793 12.435 13.0977C13.4573 12.3176 13.9571 11.087 13.7716 9.80337Z" />
      </g>
      <defs>
        <clipPath id="clip0_1_5">
          <rect width="21" height="23" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

import {
  displayLandingScreen,
  WaitingScreen,
} from '@components/screens/LandingScreen';
import useObservable from '@hooks/useObservable';
import { sessionQuery } from '@store/session/session.query';
import React from 'react';
import { LoggedRoutes } from './LoggedRoutes';
import { NotLoggedRoutes } from './NotLoggedRoutes';

export const AppRouter = () => {
  if (displayLandingScreen()) return <WaitingScreen />;
  const isLoggedIn = useObservable(sessionQuery.isLoggedIn$);
  return isLoggedIn ? <LoggedRoutes /> : <NotLoggedRoutes />;
};

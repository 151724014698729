import { LabelStore, labelStore as store } from './label.store';
import { from, Observable, take } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { get, patch, post, remove } from '../../utils/axios';
import { AxiosResponse } from 'axios';
import { LabelCriteriaId, LabelId } from '@zdlibs/domain/label';
import {
  CreateLabelRequest,
  LabelResponse,
  RenameLabelRequest,
} from '@zdlibs/shared/dto';
import {
  capsuleLabelService,
  CapsuleLabelService,
} from '../capsule/capsule-label/capsule-label.service';

export class LabelService {
  constructor(
    private labelStore: LabelStore,
    private capsuleLabelService: CapsuleLabelService
  ) {}

  findAll(): Observable<void> {
    return from(get<LabelResponse[]>('capsule/labels')).pipe(
      map((res) => this.labelStore.set(res.data)),
      take(1)
    );
  }

  create(body: CreateLabelRequest): Observable<LabelResponse> {
    return from(
      post<CreateLabelRequest, LabelResponse>('capsule/labels', body)
    ).pipe(
      map((res) => {
        this.labelStore.add(res.data);
        return res.data;
      }),
      take(1)
    );
  }

  remove(id: LabelId): Observable<AxiosResponse> {
    return from(remove('capsule/labels/' + id)).pipe(
      tap(() => this.labelStore.remove(id)),
      take(1)
    );
  }

  patch(id: LabelId, body: RenameLabelRequest): Observable<AxiosResponse> {
    return from(
      patch<RenameLabelRequest, LabelResponse>('capsule/labels/' + id, body)
    ).pipe(
      tap((res) => {
        this.labelStore.update(id, res.data);
      }),
      take(1)
    );
  }

  patchCriteria(
    id: LabelCriteriaId,
    labelId: LabelId,
    body: RenameLabelRequest
  ): Observable<AxiosResponse> {
    return from(
      patch<RenameLabelRequest, LabelResponse>(
        'capsule/labels/criteria/' + id,
        body
      )
    ).pipe(
      tap((res) => {
        this.labelStore.update(labelId, res.data);
        this.capsuleLabelService
          .updateCapsuleLabelCriteria(
            id,
            res.data.criteria.find((c) => c.id === id)
          )
          .subscribe();
      }),
      take(1)
    );
  }

  removeCriteria(
    id: LabelCriteriaId,
    labelId: LabelId
  ): Observable<AxiosResponse> {
    return from(remove('capsule/labels/criteria/' + id)).pipe(
      tap(() => {
        this.labelStore.removeCriteria(id, labelId);
        this.capsuleLabelService.removeCapsuleLabelCriteria(id).subscribe();
      }),
      take(1)
    );
  }

  createCriteria(
    labelId: LabelId,
    body: CreateLabelRequest
  ): Observable<LabelResponse> {
    return from(
      post<CreateLabelRequest, LabelResponse>(
        'capsule/labels/' + labelId + '/criteria',
        body
      )
    ).pipe(
      map((res) => {
        this.labelStore.update(labelId, res.data);
        return res.data;
      }),
      take(1)
    );
  }
}

export const labelService = new LabelService(store, capsuleLabelService);

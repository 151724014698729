import { ID, QueryEntity } from '@datorama/akita';

import { UserState, UserStore, userStore } from './user.store';
import { Capsule } from '@store/capsule/capsule.model';
import { map } from 'rxjs/operators';
import { take } from 'rxjs';

export class UserQuery extends QueryEntity<UserState> {
  constructor(protected store: UserStore) {
    super(store);
  }

  users$ = this.selectAll({
    filterBy: (user) => !user.deleted,
  });
  isUploadingImage$ = this.select((state) => state.ui.isUploadingImage);

  getParticipatingUsersByCapsulePariticpatingUsers(
    capsulePariticpatingUsers: Capsule['participatingUsers']
  ) {
    return this.selectAll({
      filterBy: (user) =>
        capsulePariticpatingUsers
          .map((c) => c.participatingUserId)
          ?.includes(user.id as number),
    });
  }

  getInvitedUsersByCapsuleInvitedUsers(
    capsuleInvitedUsers: Capsule['invitedUsers']
  ) {
    return this.selectAll({
      filterBy: (user) =>
        capsuleInvitedUsers
          ?.map((c) => c.invitedUserId)
          ?.includes(user.id as number),
    });
  }
}

export const userQuery = new UserQuery(userStore);

import React from 'react';
import { Helmet } from 'react-helmet';

export const AppHead = () => (
  <Helmet>
    <title>
      {(window as any).env.REACT_APP_APP_NAME
        ? (window as any).env.REACT_APP_APP_NAME
        : 'ZideeUp'}
    </title>
  </Helmet>
);

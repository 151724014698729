import NunitoRegular from '../assets/fonts/nunito-v22-latin-regular.woff';
import NunitoRegular2 from '../assets/fonts/nunito-v22-latin-regular.woff2';
import NunitoBold from '../assets/fonts/nunito-v22-latin-700.woff';
import NunitoBold2 from '../assets/fonts/nunito-v22-latin-700.woff2';
import NunitoBlack from '../assets/fonts/nunito-v22-latin-900.woff';
import NunitoBlack2 from '../assets/fonts/nunito-v22-latin-900.woff2';

export const nunitoFontRegular = {
  fontFamily: 'nunito',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
    local(''),
    url(${NunitoRegular}) format('woff'),
    url(${NunitoRegular2}) format('woff2')
  `,
};

export const nunitoFontBold = {
  fontFamily: 'nunito',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 700,
  src: `
    local(''),
    url(${NunitoBold}) format('woff'),
    url(${NunitoBold2}) format('woff2')
  `,
};

export const nunitoFontBlack = {
  fontFamily: 'nunito',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 900,
  src: `
    local(''),
    url(${NunitoBlack}) format('woff'),
    url(${NunitoBlack2}) format('woff2')
  `,
};
export default nunitoFontBold;

import React, { useState } from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';

import Logo from '../../../assets/ZIDEEUP.svg';
import BgSvg from '../../../assets/path.svg';
import TextInput from '../../atoms/TextInput/TextInput.view';
import PrimaryButton from '../../atoms/Buttons/PrimaryButton.view';
import { validateEmail } from '../../../utils/validate';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.primary.main,
    backgroundImage: `url(${BgSvg})`,
    textAlign: 'center',
    borderRadius: 10,
    padding: theme.spacing(4),
    color: theme.palette.background.default,
  },
  content: { maxWidth: 400 },
  logo: {
    paddingTop: 130,
    paddingBottom: 130,
  },
  link: {
    userSelect: 'none',
    cursor: 'pointer',
    textDecoration: 'underline',
  },
  registerButton: {
    width: '90%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: '5%',
  },
}));

const RegisterView = ({ onRegister, onClickLogin, error, loading = false }) => {
  const classes = useStyles();

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [verifiedPassword, setVerifiedPassword] = useState('');

  return (
    <Grid
      container
      direction="column"
      justifyContent="space-between"
      alignItems="center"
      className={classes.container}
    >
      <Grid item>
        <Grid container direction="column" justifyContent="center" spacing={10}>
          <Grid item className={classes.content}>
            <img className={classes.logo} src={Logo} alt="Logo" />
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextInput
                  type="email"
                  onChange={setUsername}
                  disabled={loading}
                />
              </Grid>
              <Grid item xs={12}>
                <TextInput
                  type="password"
                  placeholder="Mot de passe"
                  onChange={setPassword}
                  disabled={loading}
                />
              </Grid>
              <Grid item xs={12}>
                <TextInput
                  type="password"
                  placeholder="Mot de passe"
                  onChange={setVerifiedPassword}
                  disabled={loading}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <div className={classes.registerButton}>
              <PrimaryButton
                onClick={() => {
                  if (validateEmail(username) && verifiedPassword === password)
                    onRegister({ username, password });
                }}
                loading={loading}
              >
                S'inscrire
              </PrimaryButton>
            </div>
          </Grid>
          {!!error && (
            <Grid item>
              <Typography variant="body2">{error}</Typography>
            </Grid>
          )}
        </Grid>
      </Grid>

      <Grid item>
        <Typography variant="body2">
          Vous avez un compte ?{' '}
          <span className={classes.link} onClick={onClickLogin}>
            Se connecter
          </span>
        </Typography>
      </Grid>
    </Grid>
  );
};

export default RegisterView;

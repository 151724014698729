import { ID, QueryEntity } from '@datorama/akita';

import { map } from 'rxjs/operators';

import { capsuleQuery } from '../capsule/capsule.query';
import { SolutionState, SolutionStore, solutionStore } from './solution.store';

export class SolutionQuery extends QueryEntity<SolutionState> {
  constructor(protected store: SolutionStore) {
    super(store);
  }

  isUploadingDocument$ = this.select((state) => state.ui.isUploadingDocument);
  getCapsuleIdeasById(capsuleId: ID) {
    return this.selectAll().pipe(
      map((solutions) =>
        solutions
          .filter(
            (solution) =>
              solution.capsuleId === capsuleId && !solution.activated
          )
          .sort(
            (a, b) => b.nbVoteUp - b.nbVoteDown - (a.nbVoteUp - a.nbVoteDown)
          )
      )
    );
  }
}

export const solutionQuery = new SolutionQuery(solutionStore);

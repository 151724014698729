import { QueryEntity } from '@datorama/akita';
import { map } from 'rxjs';
import * as R from 'ramda';

import { TagState, TagStore, tagStore } from './tag.store';
import { isArchived, isOldVersionTag } from './tag.model';

export class TagQuery extends QueryEntity<TagState> {
  constructor(protected store: TagStore) {
    super(store);
  }

  tags$ = this.selectAll();
  availableTags$ = this.selectAll().pipe(
    map(R.reject(isArchived && isOldVersionTag))
  );
  isEditableTagListModalOpen$ = this.select(
    (state) => state.ui.isEditableTagListModalOpen
  );
}

export const tagQuery = new TagQuery(tagStore);

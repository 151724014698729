import { ID } from '@datorama/akita';

import { User } from '../user/user-permissions.model';
import { Constellation } from './constellation.model';

export class ConstellationUser {
  userId: ID;
  constellationId: ID;
  isReferentConstellation: boolean;
  accepted: boolean;
  allowed: boolean;
  isNotificationsEnabled: boolean;
  notifiyOnMyCapsulesActivities: boolean;
  notifyOnMentions: boolean;
  notifyOnNewTrends: boolean;
  user: User;
  memberSince?: Date;
  invitedAt: Date;
}

export const formatUserToEmptyConstellationUser = (
  user: User
): ConstellationUser => {
  return {
    userId: user.id,
    constellationId: null,
    isReferentConstellation: false,
    accepted: false,
    allowed: true,
    isNotificationsEnabled: true,
    notifiyOnMyCapsulesActivities: true,
    notifyOnMentions: true,
    notifyOnNewTrends: true,
    user,
    memberSince: null,
    invitedAt: null,
  };
};

export const formatAdminConstellationUser = (
  user: User,
  constellation: Constellation
): ConstellationUser => ({
  userId: user.id,
  constellationId: constellation.id,
  isReferentConstellation: true,
  accepted: true,
  allowed: true,
  isNotificationsEnabled: true,
  notifiyOnMyCapsulesActivities: true,
  notifyOnMentions: true,
  notifyOnNewTrends: true,
  user,
  memberSince: new Date(),
  invitedAt: new Date(),
});

import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';

import { User } from './user-permissions.model';

// export type UserState = EntityState<User>

export interface UserState extends EntityState<User> {
  ui: {
    isUploadingImage: boolean;
  };
}

export const initialUserState: UserState = {
  ui: {
    isUploadingImage: false,
  },
};

@StoreConfig({
  name: 'user',
})
export class UserStore extends EntityStore<UserState> {
  constructor() {
    super(initialUserState);
  }
}

export const userStore = new UserStore();

/**
 * FormData Helper. Takes an object, returns the FormData() element with deconstructed props.
 *
 * @param {*} obj
 * @returns FormData
 */
export const formDataHelper = (obj) => {
  const formData = new FormData();

  Object.keys(obj).forEach((key) => {
    formData.append(key, obj[key]);
  });

  return formData;
};

import {
  Palette,
  PaletteColor,
  PaletteOptions,
  SimplePaletteColorOptions,
} from '@material-ui/core/styles/createPalette';
import { statusColors } from '@store/capsule/capsule-status/capsule-status.model';

export interface PaletteCapsuleState {
  action: string;
  pending: string;
  progress: string;
  resolved: string;
  notResolved: string;
  archived: string;
}

export interface PaletteStatus {
  success: string;
  alert: string;
  successLight: string;
  alertLight: string;
}

export interface PaletteZideeUp extends Palette {
  white: {
    main: string;
  };
  black: {
    main: string;
  };
  primary: Palette['primary'] & { lighter: string; lightMain: string };
  tertiary: Partial<PaletteColor>;
  status: PaletteStatus;
  state: PaletteCapsuleState;
}

export interface ZideeUpPaletteOptions extends PaletteOptions {
  white: {
    main: string;
  };
  black: {
    main: string;
  };
  primary: SimplePaletteColorOptions & { lighter: string; lightMain: string };
  tertiary: { main: string; dark: string };
  status: PaletteStatus;
  state: PaletteCapsuleState;
}

export const palette: ZideeUpPaletteOptions = {
  white: {
    main: '#fff',
  },
  black: {
    main: '#000000',
  },
  primary: {
    main: '#0063C6',
    lightMain: '#7cbaed',
    light: '#019DBA',
    lighter: '#badefc',
    dark: '#496D93',
  },
  secondary: {
    main: '#e10053',
    light: '#ffbfb7',
  },
  tertiary: {
    main: '#F7B267',
    dark: '#FFBE00',
  },
  background: {
    default: '#fff',
    paper: '#f6f6f6',
  },
  grey: {
    200: '#fcfcfc',
    300: '#f8f9fc',
    400: '#EAF0F2',
    500: '#496D93',
    600: '#909090',
  },
  text: {
    primary: '#191919',
  },
  status: {
    success: '#23eca3',
    successLight: '#50e8b5',
    alert: '#e10053',
    alertLight: '#d7668f',
  },
  state: statusColors,
};
export default palette;

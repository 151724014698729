import React from 'react';

/**
 * Allows to detect if current client is using a mobile device.
 * const { isMobile } = useDeviceDetect()
 *
 * @returns
 */
export const useDeviceDetect = () => {
  const isMobile = React.useMemo(() => {
    const userAgent =
      typeof window.navigator === 'undefined' ? '' : navigator.userAgent;
    const isMobile = Boolean(
      userAgent.match(
        /Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i
      )
    );
    return isMobile;
  }, [window.navigator, navigator.userAgent]);

  return { isMobile };
};

export declare interface IsMobileProp {
  isMobile: boolean;
}

export default useDeviceDetect;

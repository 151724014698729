import {
  createTheme,
  Theme,
  responsiveFontSizes,
} from '@material-ui/core/styles';
import {
  nunitoFontRegular,
  nunitoFontBlack,
  nunitoFontBold,
} from './nunitoFont';
import {
  catamaranFont,
  catamaranFontBold,
  catamaranFontSemiBold,
} from './catamaranFont';
import palette, { PaletteZideeUp } from './palette';

export interface ThemeZideeUp extends Omit<Theme, 'palette'> {
  palette: PaletteZideeUp;
  radius: Record<string, number>;
  shadow: Record<number, string>;
}

const spacing = 4;

const createPlaceholderStyle = (placeholder) => ({
  '&::placeholder': placeholder,
  '&::-webkit-input-placeholder': placeholder,
  '&::-moz-placeholder': placeholder,
  // Firefox 19+
  '&:-ms-input-placeholder': placeholder,
  // IE 11
  '&::-ms-input-placeholder': placeholder,
});

const placeholder = {
  opacity: 1,
  color: palette.primary.dark,
};

const theme: ThemeZideeUp = createTheme({
  spacing,
  palette,
  typography: {
    fontFamily: 'Catamaran, Arial, sans-serif',
    fontSize: 16,
    h1: {
      fontFamily: 'Nunito, Arial, sans-serif',
      fontSize: 32,
      fontWeight: 700,
    },
    h2: {
      fontSize: 26,
      fontFamily: 'Nunito, Arial, sans-serif',
      fontWeight: 700,
    },
    h3: {
      fontSize: 24,
      fontFamily: 'Nunito, Arial, sans-serif',
      fontWeight: 700,
    },
    h4: {
      fontSize: 22,
      fontFamily: 'Nunito, Arial, sans-serif',
      fontWeight: 700,
    },
    h5: {
      fontSize: 18,
      fontFamily: 'Nunito, Arial, sans-serif',
      fontWeight: 700,
    },
    body1: {
      fontSize: 16,
    },
  },
  radius: {
    xs: 4,
    s: 8,
    m: 10,
    l: 16,
    xl: 20,
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  shadow: {
    100: '0px 3px 6px #D6D6D629',
  },
  // Checkout material-ui component source's to find the name of a CSS rule:
  // https://github.com/mui/material-ui/tree/v4.9.5/packages/material-ui/src/
  overrides: {
    MuiCssBaseline: {
      // @ts-ignore
      '@global': {
        '@font-face': [
          nunitoFontRegular,
          nunitoFontBold,
          nunitoFontBlack,
          catamaranFont,
          catamaranFontSemiBold,
          catamaranFontBold,
        ],
        body: {
          backgroundColor: '#f8f9fc',
        },
      },
    },
    MuiButton: {
      root: {
        textTransform: 'none',
        minWidth: 48,
        fontSize: '1rem',
        lineHeight: '1',
        fontWeight: 'bold',
      },
      contained: {
        boxShadow: 'none',
        border: '2px solid',
        '&:hover': {
          boxShadow: 'none',
        },
      },
      outlined: {
        borderWidth: '2px',
        borderColor: 'inherit',
      },
      outlinedPrimary: {
        borderWidth: 2,
        '&:hover': {
          borderWidth: 2,
        },
      },
      outlinedSecondary: {
        borderWidth: 2,
        '&:hover': {
          borderWidth: 2,
        },
      },
      text: {
        fontWeight: 'normal',
      },
    },
    MuiInputBase: {
      root: {
        height: 'auto',
        backgroundColor: palette.white.main,
        '&$focused $input': createPlaceholderStyle({
          opacity: 0,
        }),
      },
      input: createPlaceholderStyle(placeholder),
    },
    MuiInputLabel: {
      root: {
        transform: 'none',
      },
      formControl: {
        position: 'initial',
        transform: 'none',
        paddingBottom: spacing,
      },
      shrink: {
        transform: 'none',
      },
      outlined: {
        color: palette.text.primary,
        fontWeight: 'bold',
        transform: 'none',
        '&$shrink': {
          transform: 'none',
        },
      },
    },
    MuiTextField: {
      root: {
        width: '100%',
      },
    },
    MuiOutlinedInput: {
      root: {
        '&$focused $notchedOutline': {
          borderColor: palette.primary.dark,
          borderWidth: 1,
        },
      },
      input: {
        paddingTop: 11,
        paddingBottom: 11,
      },
      notchedOutline: {
        borderColor: palette.primary.main,
      },
    },
    MuiInputAdornment: {
      root: {
        color: palette.primary.main,
      },
    },
    PrivateNotchedOutline: {
      root: {
        borderWidth: 1,
        borderRadius: 6,
        top: 0,
      },
      legend: {
        display: 'none',
      },
      legendLabelled: {
        display: 'none',
      },
      legendNotched: {
        display: 'none',
      },
    },
    MuiCheckbox: {
      root: {
        '&$checked': {
          background: 'none',
        },
      },
    },
    MuiFormControlLabel: {
      root: {
        marginLeft: 0,
      },
    },
    MuiFormLabel: {
      root: {
        fontFamily: 'Catamaran, Arial, sans-serif',
        fontSize: 16,
        fontWeight: 'bold',
        color: palette.text.primary,
        '&$focused': {
          color: 'inherit',
        },
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: palette.grey[400],
      },
    },
    MuiDialogTitle: {
      root: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: 0,
        minHeight: 48,
      },
    },
    MuiDialogContent: {
      root: {
        padding: '24px 24px 12px',
        backgroundColor: palette.grey[300],

        '& > .MuiGrid-container': {
          width: '100%',
          margin: 0,
          padding: 12,
          backgroundColor: 'white',
        },
      },
    },
    MuiDialogActions: {
      root: {
        padding: '12px 24px',
        backgroundColor: '#fff',
      },
    },
    MuiSelect: {
      root: {
        '&:focus': {
          borderRadius: 6,
        },
      },
      icon: {
        color: palette.primary.main,
      },
      select: {
        '&:focus': {
          backgroundColor: 'none',
        },
      },
    },
    MuiSwitch: {
      root: {
        width: 52,
        height: 24,
        padding: 0,
      },
      switchBase: {
        left: 1,
        color: 'white',
      },
      colorSecondary: {
        padding: 2,
        '&$checked': {
          transform: 'translateX(27px)',
          color: 'white',
          '& + $track': {
            backgroundColor: palette.status.success,
            opacity: 1,
            border: 'none',
          },
        },
        '&$focusVisible $thumb': {
          color: 'white',
          border: 'none',
        },
      },
      thumb: {
        width: 19,
        height: 19,
        boxShadow: 'none',
      },
      track: {
        borderRadius: 26 / 2,
        backgroundColor: palette.primary.dark,
        opacity: 1,
      },
      checked: {},
    },
    MuiAvatar: {
      root: {
        width: 48,
        height: 48,
      },
    },
    MuiLinearProgress: {
      root: {
        height: 36,
        borderRadius: 6,
      },
      bar: {
        borderRadius: 6,
      },
      colorPrimary: {
        backgroundColor: 'transparent',
      },
      barColorPrimary: {
        backgroundColor: palette.grey[400],
      },
    },
    MuiAccordion: {
      root: {
        '&::before': {
          backgroundColor: '#EAEAEA',
        },
        '&.MuiPaper-elevation1': {
          boxShadow: 'none',
        },
        '&.Mui-expanded': {
          margin: 0,
        },
      },
    },
    MuiAccordionSummary: {
      root: {
        padding: 0,
        fontSize: 18,
        fontWeight: 'bold',
      },
    },
    MuiAccordionDetails: {
      root: {
        flexDirection: 'column',
      },
    },
    MuiPaper: {
      root: {
        backgroundColor: 'white',
      },
      elevation1: {
        boxShadow: '0px 3px 6px #D6D6D629',
      },
    },
    MuiTableRow: {
      root: {
        '&.MuiTableRow-hover:hover': {
          backgroundColor: palette.grey[400],
        },
      },
    },
    MuiPopover: {
      paper: {
        padding: '12px 16px',

        '&.MuiPaper-elevation8': {
          boxShadow: '0px 3px 6px #00000029',
        },

        '& > div': {
          display: 'flex',
          flexDirection: 'column',
        },
        '& .MuiButton-textPrimary': {
          color: palette.text.primary,
          justifyContent: 'flex-start',

          '&:not(:first-child)': {
            marginTop: 10,
          },
          '&:not(:last-of-type)': {
            marginBottom: 10,
          },
        },

        '& .MuiDivider-root': {
          display: 'none',
        },
      },
    },
    MuiRating: {
      root: {
        gap: 4,
      },
    },
  },
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
    MuiButton: {
      variant: 'contained',
    },
    MuiCheckbox: {
      disableRipple: true,
    },
    MuiRadio: {
      disableRipple: true,
    },
    MuiTextField: {
      variant: 'outlined',
    },
    MuiInputLabel: {
      disableAnimation: true,
      shrink: true,
    },
    MuiSelect: {
      disableUnderline: true,
      variant: 'outlined',

      MenuProps: {
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        transformOrigin: {
          vertical: 'center',
          horizontal: 'left',
        },
        getContentAnchorEl: null,
      },
    },
  },
  MuiPopover: {},
}) as unknown as ThemeZideeUp;

export const responsiveTheme = responsiveFontSizes(theme);

export default responsiveTheme;

import { from, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

export const fromRequest = (promise: Promise<any>) =>
  from(promise).pipe(catchError((_err, _caught) => of({})));

export const formatFileUrlForDownload = (filename: string): string => {
  if (filename && filename.includes('://')) {
    return filename;
  }

  if (filename?.[0] !== '/') {
    filename = '/' + filename;
  }

  return `${(window as any).env.REACT_APP_BACK_WEB_URL}${filename}`;
};

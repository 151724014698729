import {
  ActiveState,
  EntityState,
  EntityStore,
  ID,
  StoreConfig,
} from '@datorama/akita';

import { Constellation } from './constellation.model';

// export type ConstellationState = EntityState<Constellation>

export interface ConstellationState
  extends EntityState<Constellation>,
    ActiveState {
  active: ID;
  ui: {
    isUploadingImage: boolean;
  };
}

export const initialConstellationState: ConstellationState = {
  active: null,
  ui: {
    isUploadingImage: false,
  },
};

@StoreConfig({
  name: 'constellation',
})
export class ConstellationStore extends EntityStore<ConstellationState> {
  constructor() {
    super(initialConstellationState);
  }
}

export const constellationStore = new ConstellationStore();

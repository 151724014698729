import { QueryEntity } from '@datorama/akita';
import { map } from 'rxjs/operators';

import { sessionService } from '../session/session.service';
import { CompanyState, CompanyStore, companyStore } from './company.store';

export class CompanyQuery extends QueryEntity<CompanyState> {
  constructor(protected store: CompanyStore) {
    super(store);
  }

  mycompany$ = this.selectAll().pipe(
    // tap(solutions => console.log('capsuleSolutions', capsuleQuery.getActiveId(), solutions)),
    map((companies) =>
      companies.find(
        (company) => company.id === sessionService.currentUser().companyId
      )
    )
  );

  isValidationWorkFlowEnabled() {
    return this.mycompany$.pipe(
      map((company) => company.isValidationFlowControlled)
    );
  }
}

export const companyQuery = new CompanyQuery(companyStore);

import React, { useState } from 'react';

import { makeStyles, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';

import BgSvg from '../../../assets/path.svg';
import Logo from '../../../assets/ZIDEEUP.svg';
import { validateEmail } from '../../../utils/validate';
import PrimaryButton from '@components/atoms/Buttons/PrimaryButton.view';
import TextInput from '@components/atoms/TextInput/TextInput.view';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.primary.main,
    backgroundImage: `url(${BgSvg})`,
    textAlign: 'center',
    borderRadius: 10,
    padding: theme.spacing(4),
    color: theme.palette.background.default,
  },
  content: { maxWidth: 400 },
  logo: {
    paddingTop: 130,
    paddingBottom: 130,
  },
  link: {
    userSelect: 'none',
    cursor: 'pointer',
    textDecoration: 'underline',
  },
  resetPassRequestBtn: {
    width: '90%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: '5%',
  },
}));

export declare interface PasswordResetRequestViewProps {
  onPasswordResetRequest: (email: string) => void;
}

const PasswordResetRequestView = ({ onPasswordResetRequest }) => {
  const classes = useStyles();

  const [email, setEmail] = useState('');
  const [isError, setIsError] = useState(false);
  const [hasSent, setHasSent] = useState(false);

  return (
    <Grid
      container
      direction="column"
      justifyContent="space-between"
      alignItems="center"
      className={classes.container}
    >
      <Grid item>
        <Grid container direction="column" justifyContent="center" spacing={10}>
          <Grid item className={classes.content}>
            <img className={classes.logo} src={Logo} alt="Logo" />
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="body2">
                  {hasSent
                    ? 'Surveillez votre boîte email, nous vous avons envoyé un lien permettant de réinitialiser votre mot de passe'
                    : 'Vous souhaitez récupérer votre mot de passe ?'}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TextInput
                  placeholder="Email"
                  onBlur={(text) => setEmail(text)}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <div className={classes.resetPassRequestBtn}>
              <PrimaryButton
                onClick={() => {
                  if (validateEmail(email)) {
                    onPasswordResetRequest(email);
                    setIsError(false);
                    setHasSent(true);
                  } else {
                    setIsError(true);
                  }
                }}
              >
                Envoyer
              </PrimaryButton>
            </div>
          </Grid>
          {isError && (
            <Grid item>
              <Typography variant="body2">
                L'email saisi n'est pas valide.
              </Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PasswordResetRequestView;

import { makeStyles } from '@material-ui/core';
import BgSvg from '../../../assets/path.svg';

export const useStyles = makeStyles((theme) => ({
  parent: {
    maxWidth: 1440,
    borderRadius: 10,
    // marginLeft: '5%'
  },
  container: {
    width: '95%',
    height: '95vh',
    backgroundColor: theme.palette.primary.main,
    backgroundImage: `url(${BgSvg})`,
    textAlign: 'center',
    // borderRadius: 10,
    padding: theme.spacing(4),
    color: theme.palette.background.default,
    borderRadius: 20,
    zIndex: 150,
    minHeight: 590,
    minWidth: 290,
  },
  contentWrapper: {
    maxWidth: 272,
  },
  link: {
    userSelect: 'none',
    cursor: 'pointer',
    textDecoration: 'underline',
  },
  loginButton: {
    width: '90%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: '5%',
    borderRadius: 10,
    boxShadow: '3px 5px 16px 0 #014E9D',

    '& button': {
      width: '100%',
      borderRadius: 0,
    },
  },
  message: {
    maxWidth: 334,
  },
  signInSlide: {
    marginLeft: -20,
  },
}));

import SvgIcon from '@material-ui/core/SvgIcon';
import React from 'react';

export const Poll = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    fill="#0063C6"
  >
    <g clipPath="url(#clip0_1_20)">
      <path d="M17.1317 0H2.0558C0.920829 0 0 0.920829 0 2.0558V17.1317C0 18.2667 0.920829 19.1875 2.0558 19.1875H17.1317C18.2667 19.1875 19.1875 18.2667 19.1875 17.1317V2.0558C19.1875 0.920829 18.2667 0 17.1317 0ZM6.85268 14.3906C6.85268 14.7692 6.54602 15.0759 6.16741 15.0759H4.79688C4.41826 15.0759 4.11161 14.7692 4.11161 14.3906V8.90848C4.11161 8.52987 4.41826 8.22321 4.79688 8.22321H6.16741C6.54602 8.22321 6.85268 8.52987 6.85268 8.90848V14.3906ZM10.9643 14.3906C10.9643 14.7692 10.6576 15.0759 10.279 15.0759H8.90848C8.52987 15.0759 8.22321 14.7692 8.22321 14.3906V4.79688C8.22321 4.41826 8.52987 4.11161 8.90848 4.11161H10.279C10.6576 4.11161 10.9643 4.41826 10.9643 4.79688V14.3906ZM15.0759 14.3906C15.0759 14.7692 14.7692 15.0759 14.3906 15.0759H13.0201C12.6415 15.0759 12.3348 14.7692 12.3348 14.3906V11.6496C12.3348 11.2709 12.6415 10.9643 13.0201 10.9643H14.3906C14.7692 10.9643 15.0759 11.2709 15.0759 11.6496V14.3906Z" />
    </g>
    <defs>
      <clipPath id="clip0_1_20">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

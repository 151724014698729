import React from 'react';

export const Messages = () => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1_2)">
      <path
        d="M14.4444 7.77778H14.4556ZM10 7.77778H10.0111ZM5.55556 7.77778H5.56667ZM0 7.77778C0 1.83007 2.35294 0 10 0C17.6471 0 20 1.83007 20 7.77778C20 13.7254 17.6471 15.5556 10 15.5556C9.62133 15.5556 9.25567 15.5511 8.90267 15.5418L4.44444 20V14.9932C1.14756 14.0769 0 11.9314 0 7.77778Z"
        fill="#0063C6"
      />
      <path
        d="M14.4444 7.77778H14.4556M10 7.77778H10.0111M5.55556 7.77778H5.56667M0 7.77778C0 1.83007 2.35294 0 10 0C17.6471 0 20 1.83007 20 7.77778C20 13.7254 17.6471 15.5556 10 15.5556C9.62133 15.5556 9.25567 15.5511 8.90267 15.5418L4.44444 20V14.9932C1.14756 14.0769 0 11.9314 0 7.77778Z"
        stroke="#F5F5F5"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1_2">
        <rect width="22" height="22" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

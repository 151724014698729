import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { sessionService } from '@store/session/session.service';

import SignInScreenView from './SignInScreen.view';
import { features } from '../../../../../../config/environments/environment';
import { HOME } from '@routes/routes.controller';

export declare interface SignInScreenProps {}

const SignInScreen = ({}: SignInScreenProps) => {
  const location = useLocation();
  const history = useHistory();
  const [signInLoading, setSignInLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<string>();
  const [email, setEmail] = useState<string>();

  // Checks for an error message coming from query string or state.
  useEffect(() => {
    const errorMsg =
      location.state?.error ||
      new URLSearchParams(location.search).get('error');
    if (errorMsg) setMessage(errorMsg);
    if (features.sso) {
      ssoLogin(true);
    }
    return () => {};
  }, []);

  // Checks for a message coming from query string or state.
  useEffect(() => {
    const msg =
      location.state?.message ||
      new URLSearchParams(location.search).get('msg');
    if (msg) setMessage(msg);
  }, []);

  // Checks for an email coming from query string or state.
  useEffect(() => {
    const email =
      location.state?.email ||
      new URLSearchParams(location.search).get('email');
    if (email) setEmail(email);
  }, []);

  const ssoLogin = async (noRedirect?: boolean) => {
    setSignInLoading(true);
    sessionService.ssoLogin(noRedirect).subscribe({
      next: () => {
        setSignInLoading(false);
        setMessage(
          'Authentification réussie. Vous allez être redirigé vers votre espace.'
        );
        const redirect = history?.location?.pathname || HOME;
        setTimeout(() => history.push(redirect), 500);
      },
      error: (err) => {
        console.log(err.message);
        setSignInLoading(false);
        setMessage(err.message + ' ' + 'veuillez recharger la page');
      },
    });
  };

  const onSignIn = async (email: string) => {
    setSignInLoading(true);
    sessionService.sendLoginLink(email).subscribe({
      next: () => {
        setSignInLoading(false);
        setMessage(
          'Nous vous avons envoyé un lien vous permettant de vous connecter'
        );
      },
      error: (err) => {
        console.log(err.message);
        setSignInLoading(false);
        setMessage(err.message);
      },
    });
  };

  return (
    <SignInScreenView
      message={message}
      initialEmail={email}
      onSignIn={onSignIn}
      loading={signInLoading}
    />
  );
};

export default SignInScreen;

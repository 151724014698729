import React from 'react';

import Button from '@material-ui/core/Button';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { SvgIconComponent } from '@material-ui/icons';
import { ButtonProps } from '@material-ui/core/Button/Button';
import { SvgIcon, Tooltip } from '@material-ui/core';
import ThumbUpView from '../Icons/ThumbUp/ThumbUp.view';

export interface IconButtonProps extends ButtonProps {
  icon: SvgIconComponent;
  isLoading?: boolean;
  onClick?: (props: any) => any;
  disabled?: boolean;
  variant?: 'text' | 'outlined' | 'contained';
  backgroundColor?: string;
  tooltip?: string;
  doubleIcon?: boolean;
  className?: string;
  iconSize?: string;
}

const useStyles = makeStyles<Theme, IconButtonProps>((theme: Theme) => ({
  root: {
    minWidth: 0,
    padding: 3,
    backgroundColor: 'none',
    border: 'none',
    borderRadius: '50%',
    '&:disabled': {
      '& span': {
        '& svg': {
          fill: theme.palette.grey.A700,
        },
      },
    },
    '&.MuiButton-textPrimary': {
      background: 'none',
    },
    '& svg': {
      width: (props) => props.iconSize || '24px',
      height: (props) => props.iconSize || '24px',
    },

    '&.MuiButton-containedPrimary': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: 48,
      height: 48,
      color: 'white',
      backgroundColor: ({ backgroundColor }) =>
        backgroundColor ? backgroundColor : theme.palette.primary.main,
      borderRadius: 6,
    },
  },
}));

const IconButtonView = (props: IconButtonProps) => {
  const classes = useStyles(props);
  let { icon, isLoading, onClick, disabled, variant, ...rest } = props;
  variant = variant ? variant : 'contained';
  const classNames = props.className
    ? `${classes.root} ${props.className}`
    : `${classes.root}`;
  const btn = () => (
    <Button
      variant={variant}
      onClick={onClick}
      className={classNames}
      disabled={disabled}
      {...rest}
    >
      <SvgIcon
        component={icon}
        color={variant === 'contained' ? 'inherit' : 'primary'}
      />
    </Button>
  );
  if (isLoading) return <>En chargement...</>;
  if (props.tooltip)
    return (
      <Tooltip
        placement="top"
        title={props.tooltip}
        disableTouchListener={true}
      >
        {btn()}
      </Tooltip>
    );
  if (props.doubleIcon)
    return (
      <Button
        variant={variant}
        onClick={onClick}
        className={classNames}
        disabled={disabled}
        {...rest}
      >
        <SvgIcon
          component={icon}
          color={variant === 'contained' ? 'inherit' : 'primary'}
        />
        <SvgIcon
          component={icon}
          color={variant === 'contained' ? 'inherit' : 'primary'}
        />
      </Button>
    );
  return <>{btn()}</>;
};

export default IconButtonView;

import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';

import { ROUTES } from '../../routes/routes';

import Register from '../../components/screens/Register';

export const SignUpPage = (props: any) => {
  const history = useHistory();
  const [signInLoading, setSignInLoading] = useState(false);
  const [error] = useState(null);

  const onClickLogin = () => {
    history.push(ROUTES.SIGN_IN_LINK);
  };

  const onRegister = ({ email, password }) => {
    setSignInLoading(true);

    // TODO
  };

  return (
    <>
      <Helmet>
        <title>
          {(window as any).env.REACT_APP_APP_NAME
            ? (window as any).env.REACT_APP_APP_NAME
            : 'ZideeUp'}{' '}
          - Inscription
        </title>
      </Helmet>
      <Register
        onRegister={onRegister}
        onClickLogin={onClickLogin}
        error={error}
        loading={signInLoading}
      />
    </>
  );
};

export default SignUpPage;

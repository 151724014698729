import React from 'react';

import LoadingScreenView, {
  LoadingScreenViewProps,
} from './LoadingScreen.view';

const LoadingScreen = (props: LoadingScreenViewProps) => (
  <LoadingScreenView {...props} />
);

export default LoadingScreen;

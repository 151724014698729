import { CapsuleValidation } from '@store/capsule/capsule-validators/capsule-validators.model';
import {
  isCapsuleCreator,
  isCapsulePilote,
  isReferentOrAdmin,
} from '@zdlibs/domain/user';
import { User } from '../../user/user-permissions.model';
import { Capsule } from '../capsule.model';

export enum CapsuleStatus {
  CREATED = 'created',
  PENDING_ACTIVATION = 'pending_activation', // En attente d'activation
  ACTIVATED = 'activated', // In progress ou activé
  FINISHED = 'finished',
  NOT_RESOLVED = 'not_resolved',
}

export enum CapsuleItemStatusColors {
  PROGRESS = 'progress', // In progress ou activé ou activated
  ARCHIVED = 'archived',
  PENDING = 'pending', // En attente d'activation
  NEW = 'new',
  ACTION = 'action',
  RESOLVED = 'resolved',
  NOT_RESOLVED = 'notResolved',
}

export const statusColors = {
  [CapsuleItemStatusColors.ACTION]: '#FFC100',
  [CapsuleItemStatusColors.NEW]: '#e10053',
  [CapsuleItemStatusColors.PENDING]: '#019DBA',
  [CapsuleItemStatusColors.PROGRESS]: '#F7B267',
  [CapsuleItemStatusColors.RESOLVED]: '#008148',
  [CapsuleItemStatusColors.NOT_RESOLVED]: '#191919',
  [CapsuleItemStatusColors.ARCHIVED]: '#496D93',
};

export const graphStatusColors = {
  [CapsuleStatus.CREATED]: statusColors[CapsuleItemStatusColors.NEW],
  [CapsuleStatus.PENDING_ACTIVATION]:
    statusColors[CapsuleItemStatusColors.PENDING],
  [CapsuleStatus.ACTIVATED]: statusColors[CapsuleItemStatusColors.PROGRESS],
  [CapsuleStatus.FINISHED]: statusColors[CapsuleItemStatusColors.RESOLVED],
};

export const getCapsuleItemStatusColors = (
  capsule: Capsule
): CapsuleItemStatusColors => {
  if (isArchived(capsule)) return CapsuleItemStatusColors.ARCHIVED;
  if (isPending(capsule)) return CapsuleItemStatusColors.PENDING;
  if (isNotResolved(capsule)) return CapsuleItemStatusColors.NOT_RESOLVED;
  if (isNew(capsule)) return CapsuleItemStatusColors.NEW;
  if (isInProgress(capsule)) return CapsuleItemStatusColors.PROGRESS;
  if (isResolved(capsule)) return CapsuleItemStatusColors.RESOLVED;
  return CapsuleItemStatusColors.ACTION;
};

//PHASE

export const isArchived = (capsule: Pick<Capsule, 'archived'>) =>
  capsule.archived;
//STATUS

export const canReactivate = (capsule: Capsule) =>
  isInProgress(capsule) || isResolved(capsule) || isNotResolved(capsule);

export const isNew = (capsule: Capsule) =>
  capsule.capsuleStatus === CapsuleStatus.CREATED;

export const isPending = (capsule: {
  capsuleStatus: Capsule['capsuleStatus'];
}) => capsule.capsuleStatus === CapsuleStatus.PENDING_ACTIVATION;

export const isInProgress = (capsule: Capsule) =>
  capsule.capsuleStatus === CapsuleStatus.ACTIVATED &&
  capsule.advancementStatus > 0 &&
  capsule.advancementStatus < 100;

export const isNotResolved = (
  capsule: Pick<Capsule, 'capsuleStatus'>
): boolean => capsule.capsuleStatus === CapsuleStatus.NOT_RESOLVED;

export const isResolved = (capsule: Pick<Capsule, 'capsuleStatus'>): boolean =>
  capsule.capsuleStatus === CapsuleStatus.FINISHED;

export const isClosed = (
  capsule: Pick<Capsule, 'capsuleStatus' | 'archived'>
): boolean =>
  isResolved(capsule) || isNotResolved(capsule) || isArchived(capsule);

export const countCapsulesByStatus =
  (capsules: Capsule[]) =>
  (filter: () => boolean): number =>
    capsules.filter(filter)?.length || 0;

// other

export const canValidateIdea = (capsuleStatus: CapsuleStatus) =>
  capsuleStatus === CapsuleStatus.CREATED ||
  capsuleStatus === CapsuleStatus.PENDING_ACTIVATION;

export const canApproveSolution = (
  capsuleStatus: CapsuleStatus,
  currentUserValidation: CapsuleValidation
) =>
  capsuleStatus === CapsuleStatus.PENDING_ACTIVATION &&
  !currentUserValidation?.isApproved;

export const canProgressSolution = (capsuleStatus: CapsuleStatus) =>
  capsuleStatus === CapsuleStatus.ACTIVATED;

export const canRejectSolution = (
  capsuleStatus: CapsuleStatus,
  currentUserValidation: CapsuleValidation
) =>
  (capsuleStatus === CapsuleStatus.PENDING_ACTIVATION ||
    capsuleStatus === CapsuleStatus.ACTIVATED) &&
  !currentUserValidation?.isRejected;

export const canActivateCapsule = (capsule: Capsule, user: User): boolean =>
  isReferentOrAdmin(user) ||
  isCapsulePilote(user)(capsule) ||
  isCapsuleCreator(user)(capsule);

export const getWordingForCapsuleStatus = (capsuleStatus: CapsuleStatus) => {
  switch (capsuleStatus) {
    case CapsuleStatus.ACTIVATED:
      return 'En Progression';
    case CapsuleStatus.CREATED:
      return 'Nouveau';
    case CapsuleStatus.FINISHED:
      return 'Résolu';
    case CapsuleStatus.NOT_RESOLVED:
      return 'Non-résolu';
    case CapsuleStatus.PENDING_ACTIVATION:
      return 'En attente';
    default:
      return 'Archivé';
  }
};
export const CAPSULE_STATUS_TITLE = {
  [CapsuleStatus.CREATED]: 'Nouvelles capsules',
  [CapsuleStatus.PENDING_ACTIVATION]: 'Capsules en attente',
  [CapsuleStatus.ACTIVATED]: 'Capsules en progression',
  [CapsuleStatus.FINISHED]: 'Capsule résolues',
};

export const CAPSULE_STATUS_DATE = (capsule: Capsule) => ({
  [CapsuleStatus.CREATED]: capsule.createdAt,
  [CapsuleStatus.PENDING_ACTIVATION]: capsule.pendingActivationAt,
  [CapsuleStatus.ACTIVATED]: capsule.activatedAt,
  [CapsuleStatus.FINISHED]: capsule.endedAt,
});

import { recapCapsuleList } from '@components/screens/CapsuleList/recapCapsuleList';
import { ID, QueryEntity } from '@datorama/akita';
import { capsuleService, CapsuleService } from '@store/capsule/capsule.service';
import { combineLatest, mergeMap, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { companyQuery } from '../company/company.query';
import { filterCapsulesToDisplay } from '../search/search.model';
import { searchQuery } from '../search/search.query';

import { sessionService } from '../session/session.service';
import { CapsuleStatus } from './capsule-status/capsule-status.model';
import { Capsule } from './capsule.model';
import { CapsuleState, CapsuleStore, capsuleStore } from './capsule.store';
import { formatCapsulesForList } from './useCases/listItemInfos/utils';

export class CapsuleQuery extends QueryEntity<CapsuleState> {
  constructor(protected store: CapsuleStore) {
    super(store);
  }

  isExtendedView$ = this.select((state) => state.ui.isExtendedView);
  isUploadingDocument$ = this.select((state) => state.ui.isUploadingDocument);
  isUploadingImage$ = this.select((state) => state.ui.isUploadingCapsuleImage);

  capsulesICanSee$ = this.selectAll({
    filterBy: [(entity) => entity.myCapsule],
  });
  allCapsules$ = this.selectAll();

  getCapsuleList(isMobile: boolean) {
    return combineLatest([
      this.allCapsules$,
      searchQuery.currentSearch$,
      this.isMyCapsulesHome$,
      sessionService.currentUser$(),
      companyQuery.isValidationWorkFlowEnabled(),
    ]).pipe(
      map(
        ([
          capsules,
          search,
          isMyCapsulesHome,
          user,
          isValidationWorkFlowEnabled,
        ]) => {
          const filteredCapsules = filterCapsulesToDisplay(
            capsules,
            search,
            user,
            isMyCapsulesHome
          );
          return {
            capsules: filteredCapsules.map(
              formatCapsulesForList(user, isMobile)
            ),
            recap: recapCapsuleList(capsules)(
              isValidationWorkFlowEnabled,
              isMyCapsulesHome
            ),
          };
        }
      )
    );
  }

  isMyCapsulesHome$ = this.select().pipe(map((res) => res.ui.isMyCapsulesHome));

  selectCapsulesByStatus(status: CapsuleStatus): Observable<Capsule[]> {
    return this.selectAll().pipe(
      map((allCapsules) => {
        return allCapsules.filter(
          (capsule) => capsule.capsuleStatus === status
        );
      })
    );
  }

  selectCapsulesCreatedByMe(): Observable<Capsule[]> {
    return this.selectAll().pipe(
      map((allCapsules) => {
        return allCapsules.filter(
          (capsule) => capsule.createdBy.id === sessionService.currentUser().id
        );
      })
    );
  }

  selectCapsuleFromId(capsuleId: ID): Observable<Capsule> {
    return this.selectAll().pipe(
      map((allCapsules) => {
        return allCapsules.find((capsule) => capsule.id === capsuleId);
      })
    );
  }
}

export const capsuleQuery = new CapsuleQuery(capsuleStore);

import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Label, LabelCriteriaId, LabelId } from '@zdlibs/domain/label';
import { RenameLabelRequest } from '@zdlibs/shared/dto';

export interface LabelState extends EntityState<Label> {}

const initLabelState: LabelState = {};

@StoreConfig({ name: 'label' })
export class LabelStore extends EntityStore<LabelState> {
  constructor() {
    super(initLabelState);
  }

  removeCriteria(id: LabelCriteriaId, labelId: LabelId) {
    return this.update(
      (label) => {
        return label.id === labelId;
      },
      (label) => ({
        ...label,
        criteria: label.criteria.filter((c) => c.id !== id),
      })
    );
  }

  patchCriteria(
    id: LabelCriteriaId,
    labelId: LabelId,
    params: RenameLabelRequest
  ) {
    return this.update(labelId, (label) => ({
      ...label,
      criteria: label.criteria.map((criteria) => {
        if (criteria.id !== id) return criteria;
        return { ...criteria, ...params };
      }),
    }));
  }
}

export const labelStore = new LabelStore();

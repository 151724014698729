import {
  isInProgress,
  isNew,
  isNotResolved,
  isResolved,
  isPending,
} from '../../capsule/capsule-status/capsule-status.model';
import { Capsule } from '../../capsule/capsule.model';
import { Search } from '../search.model';

export const filterCapsuleStatus = (capsules: Capsule[], search: Search) => {
  if (search.showNews) {
    capsules = capsules.filter((c) => isNew(c));
  }

  if (search.showActivated) {
    capsules = capsules.filter((c) => isInProgress(c));
  }

  if (search.showPending) {
    capsules = capsules.filter((c) => isPending(c));
  }

  if (search.showRealised) {
    capsules = capsules.filter((c) => isResolved(c));
  }

  if (search.showNotResolved) {
    capsules = capsules.filter((c) => isNotResolved(c));
  }
  return capsules;
};

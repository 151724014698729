import { ID } from '@datorama/akita';
import axios from 'axios';
import { from, Observable, take } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { CapsulePoll } from './capsule-poll.model';
import { capsulePollQuery } from './capsule-poll.query';
import { CapsulePollStore, capsulePollStore } from './capsule-poll.store';
import { capsuleStore } from '../capsule/capsule.store';
import { CreateCapsulePoll } from './create-capsule-poll.model';
import { EditCapsulePoll } from './edit-capsule-poll.model';

export class CapsulePollService {
  constructor(private capsulePollStore: CapsulePollStore) {}

  getPolls(capsuleId: ID): Observable<CapsulePoll[]> {
    return from(
      axios.get(
        `${
          (window as any).env.REACT_APP_BACK_WEB_URL
        }/capsules/${capsuleId}/polls`
      )
    ).pipe(
      tap((res) => {
        this.capsulePollStore.upsertMany(res.data);
      }),
      map((res) => res.data),
      take(1)
    );
  }

  createPoll(
    capsuleId: ID,
    createCapsulePoll: CreateCapsulePoll
  ): Observable<CapsulePoll> {
    return from(
      axios.post(
        `${
          (window as any).env.REACT_APP_BACK_WEB_URL
        }/capsules/${capsuleId}/polls`,
        createCapsulePoll
      )
    ).pipe(
      tap((res) => {
        this.capsulePollStore.add(res.data);
        capsuleStore.update(capsuleId, (capsule) => {
          return {
            ...capsule,
            capsulePoints: capsule.capsulePoints + 3,
          };
        });
      }),
      map((res) => res.data)
    );
  }

  patchPoll(
    capsuleId: ID,
    editCapsulePoll: EditCapsulePoll
  ): Observable<CapsulePoll> {
    return from(
      axios.patch(
        `${
          (window as any).env.REACT_APP_BACK_WEB_URL
        }/capsules/${capsuleId}/polls`,
        editCapsulePoll
      )
    ).pipe(
      tap((res) => {
        this.capsulePollStore.update(editCapsulePoll.id, (capsulePoll) => {
          return {
            ...capsulePoll,
            ...res.data,
          };
        });
      }),
      map((res) => {
        return capsulePollQuery.getEntity(editCapsulePoll.id);
      })
    );
  }

  postPollUserAnswer(
    capsulePollId: ID,
    answerId: ID,
    hasAVote: boolean
  ): Observable<CapsulePoll> {
    return from(
      axios.post(
        `${
          (window as any).env.REACT_APP_BACK_WEB_URL
        }/polls/${capsulePollId}/user-answer/${answerId}`
      )
    ).pipe(
      tap((res) => {
        this.capsulePollStore.update(capsulePollId, (capsulePoll) => {
          return {
            ...capsulePoll,
            ...res.data,
          };
        });
        if (!hasAVote) {
          capsuleStore.update(res.data.capsuleId, (capsule) => {
            return {
              ...capsule,
              capsulePoints: capsule.capsulePoints + 1,
            };
          });
        }
      }),
      map((res) => {
        return capsulePollQuery.getEntity(capsulePollId);
      })
    );
  }
  deletePollUserAnswer(
    capsulePollId: ID,
    answerId: ID
  ): Observable<CapsulePoll> {
    return from(
      axios.delete(
        `${
          (window as any).env.REACT_APP_BACK_WEB_URL
        }/polls/${capsulePollId}/user-answer/${answerId}`
      )
    ).pipe(
      tap((res) => {
        this.capsulePollStore.update(capsulePollId, (capsulePoll) => {
          return {
            ...capsulePoll,
            ...res.data,
          };
        });
        capsuleStore.update(res.data.capsuleId, (capsule) => {
          return {
            ...capsule,
            capsulePoints: capsule.capsulePoints - 1,
          };
        });
      }),
      map((res) => {
        return capsulePollQuery.getEntity(capsulePollId);
      })
    );
  }

  deletePoll(capsuleId: ID, pollId: ID): Observable<CapsulePoll> {
    return from(
      axios.delete(
        `${
          (window as any).env.REACT_APP_BACK_WEB_URL
        }/capsules/${capsuleId}/polls/${pollId}`
      )
    ).pipe(
      tap((res) => {
        this.capsulePollStore.remove(pollId);
        capsuleStore.update(capsuleId, (capsule) => {
          return {
            ...capsule,
            capsulePoints: capsule.capsulePoints - 3,
          };
        });
      }),
      map((res) => {
        return capsulePollQuery.getEntity(res.data.id);
      })
    );
  }

  // getPollsByStatus(status: PollStatus): Observable<Poll[]> {
  // 	return from(
  // 		axios.get(`${(window as any).env.REACT_APP_BACK_WEB_URL}/polls`, {
  // 			params: {
  // 				status,
  // 			},
  // 		}),
  // 	).pipe(
  // 		map(res => {
  // 			pollStore.set(res.data)
  // 			return res.data
  // 		}),
  // 	)
  // }

  // postPollAnswer(pollId: ID, answerId: ID): Observable<any> {
  // 	return from(axios.post(`${(window as any).env.REACT_APP_BACK_WEB_URL}/polls/${pollId}/userAnswer/${answerId}`))
  // }

  // fetchPollStats(pollId: ID): Observable<PollAnswerStat[]> {
  // 	return from(axios.get(`${(window as any).env.REACT_APP_BACK_WEB_URL}/polls/${pollId}/stats`)).pipe(map(res => res.data))
  // }

  // editPoll(pollId: ID, question: string, availableAnswers: AvailableAnswer[]) {
  // 	return fromRequest(
  // 		axios.patch(`${(window as any).env.REACT_APP_BACK_WEB_URL}/polls/${pollId}`, {
  // 			question: question,
  // 			availableAnswers: availableAnswers,
  // 		}),
  // 	)
  // }
}

export const capsulePollService = new CapsulePollService(capsulePollStore);

import { Capsule } from '../capsule/capsule.model';
import { Constellation } from '../constellation/constellation.model';
import { User } from '../user/user-permissions.model';
import { Tag } from '../tag/tag.model';
import * as R from 'ramda';
import { filterFraicheur } from './useCases/filterFraicheur';
import { filterCapsuleStatus } from './useCases/filterCapsuleStatus';
import {
  CapsuleStatus,
  isArchived,
} from '../capsule/capsule-status/capsule-status.model';
import { CapsuleValidatorPosition } from '../capsule/capsule-validators/capsule-position.model';
import { Category } from '@zdlibs/domain-category';
import { filterMyCapsules } from './useCases/filterMyCapsules';

export type UserPositionFilterOptions = CapsuleValidatorPosition[];

export class Search {
  searchTerm: string;
  showCapsulesICreatedOrPilote: boolean;
  showCapsulesIActivated: boolean;
  showCapsulesIParticipate: boolean;
  showCapsulesIResolved: boolean;
  showMyFavorites: boolean;
  showTrendingCapsules: boolean;
  showNews: boolean;
  showActivated: boolean;
  showPending: boolean;
  showRealised: boolean;
  showNotResolved: boolean;
  showArchived: boolean;
  showOld15Days: boolean;
  showOld30Days: boolean;
  showOld45Days: boolean;
  showOldBydate: {
    startDate: Date;
    endDate: Date;
    status: CapsuleStatus;
  };
  orderByCreatedAtDesc: boolean;
  orderByCreatedAtAsc: boolean;
  orderByCapsulePointsDesc: boolean;
  showCapsulesIValidator: boolean;
  constellations: Constellation['id'][];
  position: UserPositionFilterOptions;
  categories: { domainAndThematic: Category['id'][]; tags: Tag['id'][] };
}

const getPositionOverValidators = R.map(
  R.view(R.lensPath(['user', 'position']))
);

const sortCapsuleByPoints = (a: Capsule, b: Capsule) =>
  b.capsulePoints - a.capsulePoints;
const sortFunction = (a: Capsule, b: Capsule) => {
  const dateDiff =
    Number(new Date(a.createdAt)) - Number(new Date(b.createdAt));
  if (dateDiff !== 0) return dateDiff;
  return a.capsulePoints - b.capsulePoints;
};

export const filterCapsulesToDisplay = (
  capsules: Capsule[],
  search: Search,
  currentUser: User,
  isMyCapsulesHome?: boolean
) => {
  if (isMyCapsulesHome) {
    capsules = capsules?.filter((c) => c.myCapsule);
  }
  if (!capsules || capsules.length === 0) return [];

  if (search.constellations?.length > 0) {
    capsules = capsules.filter((c) =>
      c.constellations?.some((constellation) =>
        search.constellations?.find((sConstel) => sConstel === constellation.id)
      )
    );
  }
  if (search.position?.length > 0) {
    capsules = R.filter<Capsule>(
      (c) =>
        c.validators &&
        R.intersection(getPositionOverValidators(c.validators))(search.position)
          .length > 0
    )(capsules);
  }
  if (search.categories?.tags?.length > 0) {
    capsules = capsules.filter((c) =>
      c.tags?.some((tag) => search.categories?.tags.find((id) => id === tag.id))
    );
  }

  capsules = filterFraicheur(capsules, search);
  capsules = filterCapsuleStatus(capsules, search);
  capsules = filterMyCapsules(capsules, search, currentUser);

  if (search.showArchived) {
    capsules = capsules.filter((c) => isArchived(c));
  } else {
    capsules = capsules.filter((c) => !isArchived(c));
  }

  if (search && search.searchTerm) {
    capsules = capsules.filter(
      (c) =>
        c.title?.toLowerCase().includes(search.searchTerm) ||
        c.description?.toLowerCase().includes(search.searchTerm) ||
        c.tags?.some((tag) =>
          tag.text?.toLowerCase().includes(search.searchTerm)
        )
    );
  }

  if (!search.orderByCapsulePointsDesc) {
    if (search.orderByCreatedAtDesc) {
      capsules = capsules.sort((a, b) => sortFunction(b, a));
    } else if (search.orderByCreatedAtAsc) {
      capsules = capsules.sort((a, b) => sortFunction(a, b));
    }
  } else {
    capsules = capsules.sort(sortCapsuleByPoints);
  }

  return capsules;
};

export type HandleSearch = (
  key: keyof Search | keyof Search['categories'],
  value?: string | number
) => void;

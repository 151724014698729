import { User } from '../../user/user-permissions.model';
import { Capsule } from '../capsule.model';
import { formatToLocalDate } from '../../../utils/date';
import {
  isArchived,
  isInProgress,
  isResolved,
  isPending,
} from '../capsule-status/capsule-status.model';
import { isCurrentUserExpert } from '@zdlibs/domain/user';

export class CapsuleValidation {
  userId: number;
  isApproved?: boolean;
  isRejected?: boolean;
  validationAt?: Date;
  text?: string;
  user: User;
}

const isValidateByEveryone = (capsule: Capsule) =>
  isPending(capsule) &&
  capsule.validators?.every((validation) => validation.isApproved);

const isRejectedByAtLeastOne = (capsule: Capsule) =>
  isPending(capsule) &&
  capsule.validators?.some((validation) => validation.isRejected);

export const isNotTimeForValidation = (capsule: Capsule) =>
  isInProgress(capsule) || isArchived(capsule) || isResolved(capsule);

const VALIDE = 'Validé';

export const getValidationMessageToDisplay = (capsule: Capsule) => {
  if (isResolved(capsule) || isInProgress(capsule)) return VALIDE;
  if (isPending(capsule) && !capsule.validators)
    return `En attente d'au moins une validation.`;
  if (isRejectedByAtLeastOne(capsule)) {
    return 'Rejetée par au moins une personne.';
  } else if (isValidateByEveryone(capsule)) {
    return `Approuvée par tous le ${formatToLocalDate(
      capsule.activatedAt,
      'DD/MM/YYYY'
    )} 🎉`;
  }
  if (isArchived(capsule)) return 'Archivé';
  return `Aucune`;
};

export const getValidationDateDisplay = (
  pendingActivationAt: Capsule['pendingActivationAt']
) => {
  if (!pendingActivationAt) return 'Aucune demande';
  return formatToLocalDate(pendingActivationAt, 'DD/MM/YYYY');
};

export const displayValidation = (
  capsule: Capsule,
  isValidationFlowControlled: boolean
) => capsule.validators?.length > 0 || isValidationFlowControlled;

export const getCurrentUserValidation = (
  capsule: Capsule,
  user: User
): CapsuleValidation =>
  capsule.validators?.find((validation) =>
    isCurrentUserExpert(validation, user)
  );

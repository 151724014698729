import axios, { AxiosResponse } from 'axios';

type EndPoint = string;

const getEnpointUrl = (endPoint: EndPoint) =>
  (window as any).env.REACT_APP_BACK_WEB_URL + '/' + endPoint;

export const get = <R = any>(endPoint: EndPoint): Promise<AxiosResponse<R>> =>
  axios.get(getEnpointUrl(endPoint));
export const post = <T = any, R = any>(
  endPoint: EndPoint,
  params: T
): Promise<AxiosResponse<R>> => axios.post(getEnpointUrl(endPoint), params);

export const remove = <T = any, R = any>(
  endPoint: EndPoint,
  params?: T
): Promise<AxiosResponse<R>> => axios.delete(getEnpointUrl(endPoint), params);

export const patch = <T = any, R = any>(
  endPoint: EndPoint,
  params: T
): Promise<AxiosResponse<R>> => axios.patch(getEnpointUrl(endPoint), params);

export const put = <T = any, R = any>(
  endPoint: EndPoint,
  params: T
): Promise<AxiosResponse<R>> => axios.put(getEnpointUrl(endPoint), params);

export const deleteHttp = <R = any>(
  endPoint: EndPoint
): Promise<AxiosResponse<R>> => axios.delete(getEnpointUrl(endPoint));

import LoadingScreen from '@components/screens/LoadingScreen';
import { initStore } from '@store/initStore';
import { sessionService } from '@store/session/session.service';
import { HomeType } from '@zdlibs/domain-capsule';
import React, { lazy, useEffect, useState } from 'react';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { connection } from '../../services/websocket';
import { entityEventsHandler } from '../../utils/entityEventsHandler';
import { ROUTES } from '../routes';

const HomePage = lazy(() => import('../../pages/Home/Home.page'));
const SettingsPage = lazy(() => import('../../pages/Settings/Settings.page'));

export const LoggedRoutes = () => {
  const history = useHistory();
  const location = useLocation();

  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    let homeType;
    if (
      location.pathname === '/home/all' ||
      location.pathname.includes('/home/newsletter')
    ) {
      homeType = HomeType.ALL;
    }

    initStore(homeType).subscribe((res) => {
      if (res instanceof Error) return setIsLoading(false);
      setIsLoading(false);
    });
    const socket = connection(sessionService.getToken());
    socket.on('entity-events', ({ eventName, entity }) => {
      entityEventsHandler(eventName, entity, history.location.pathname);
    });
  }, []);

  if (isLoading)
    return (
      <LoadingScreen
        isOpen={true}
        text={'Application en cours de chargement...'}
      />
    );
  return (
    <Switch>
      <Route path={ROUTES.HOME}>
        <React.Suspense fallback={<LoadingScreen isOpen={true} />}>
          <HomePage />
        </React.Suspense>
      </Route>
      <Route path={ROUTES.SETTINGS}>
        <React.Suspense fallback={<LoadingScreen isOpen={true} />}>
          <SettingsPage />
        </React.Suspense>
      </Route>
      <Route>
        <React.Suspense fallback={<LoadingScreen isOpen={true} />}>
          <HomePage />
        </React.Suspense>
      </Route>
    </Switch>
  );
};

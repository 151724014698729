import { uiStore, UiStore } from '@store/ui/ui.store';
import { of } from 'rxjs';

export class UiService {
  constructor(private uiStore: UiStore) {}

  setCapsuleActivityTabs(tabsNumber?: number) {
    return of(
      this.uiStore.update((state) => ({
        ...state,
        capsule: {
          activityTabs: tabsNumber,
        },
      }))
    );
  }
}

export const uiService = new UiService(uiStore);

import {
  ActiveState,
  EntityState,
  EntityStore,
  ID,
  StoreConfig,
} from '@datorama/akita';

import { Capsule } from './capsule.model';
import { AssignOrUnassignCriteriaResponseDto } from '@zdlibs/shared/dto';
import { LabelCriteria, LabelCriteriaId, LabelId } from '@zdlibs/domain/label';

export interface CapsuleState extends EntityState<Capsule>, ActiveState {
  active: ID;
  allCapsulesFetched: boolean;
  ui: {
    isEditing: boolean;
    isExtendedView: boolean;
    isUploadingDocument: boolean;
    isUploadingCapsuleImage: boolean;
    isLoadingComments: boolean;
    isMyCapsulesHome: boolean;
  };
}
export const initialCapsuleState: CapsuleState = {
  active: null,
  allCapsulesFetched: false,
  ui: {
    isEditing: false,
    isExtendedView: false,
    isUploadingDocument: false,
    isUploadingCapsuleImage: false,
    isLoadingComments: false,
    isMyCapsulesHome: true,
  },
};
@StoreConfig({
  name: 'capsule',
})
export class CapsuleStore extends EntityStore<CapsuleState> {
  constructor() {
    super(initialCapsuleState);
  }

  isMyCapsulesHome() {
    return this.getValue().ui.isMyCapsulesHome;
  }

  toggleIsCapsulesFilteredView() {
    this.update({
      ...this.getValue(),
      ui: {
        ...this.getValue().ui,
        isMyCapsulesHome: !this.isMyCapsulesHome(),
      },
    });
  }

  setCapsuleAsFetched() {
    this.update({
      ...this.getValue(),
      allCapsulesFetched: true,
    });
  }
  assignLabelCriteria(
    capsuleId: Capsule['id'],
    responseDto: AssignOrUnassignCriteriaResponseDto
  ) {
    this.update(capsuleId, (capsule) => {
      return {
        ...capsule,
        label: responseDto.label,
        criteria: [
          ...capsule.criteria.filter(
            (c) => c.labelId !== responseDto.criteria.labelId
          ),
          responseDto.criteria,
        ].sort((a, b) => {
          if (a.labelId > b.labelId) return 1;
          return -1;
        }),
      };
    });
  }
  unAssignLabelCriteria(capsuleId: Capsule['id'], labelId: LabelId) {
    this.update(capsuleId, (capsule) => {
      return {
        ...capsule,
        criteria: capsule.criteria.filter((c) => c.labelId !== labelId),
      };
    });
  }

  updateCriteria(capsules: Capsule[], updatedLabelCriteria: LabelCriteria) {
    this.update(
      capsules.map((c) => c.id),
      (c) => ({
        ...c,
        criteria: [
          ...c.criteria.filter((c) => c.id !== updatedLabelCriteria.id),
          updatedLabelCriteria,
        ],
      })
    );
  }

  removeCriteria(capsules: Capsule[], removeId: LabelCriteriaId) {
    this.update(
      capsules.map((c) => c.id),
      (c) => ({
        ...c,
        criteria: c.criteria.filter((c) => c.id !== removeId),
      })
    );
  }
}
export const capsuleStore = new CapsuleStore();

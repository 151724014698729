import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';

import { CapsulePoll } from './capsule-poll.model';

export type CapsulePollState = EntityState<CapsulePoll>;

@StoreConfig({
  name: 'capsule_poll',
})
export class CapsulePollStore extends EntityStore<CapsulePollState> {
  constructor() {
    super();
  }
}

export const capsulePollStore = new CapsulePollStore();

import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';

import { Company } from './company.model';

export type CompanyState = EntityState<Company>;

@StoreConfig({
  name: 'company',
})
export class CompanyStore extends EntityStore<CompanyState> {
  constructor() {
    super();
  }
}

export const companyStore = new CompanyStore();

import { QueryEntity } from '@datorama/akita';
import { map } from 'rxjs/operators';
import { capsuleQuery } from '../capsule/capsule.query';

import {
  ConstellationState,
  ConstellationStore,
  constellationStore,
} from './constellation.store';

export class ConstellationQuery extends QueryEntity<ConstellationState> {
  constructor(protected store: ConstellationStore) {
    super(store);
  }

  constellations$ = this.selectAll();
  isUploadingImage$ = this.select((state) => state.ui.isUploadingImage);
  getConstellationsByIds = (constellationsIds: number[]) =>
    this.selectAll({
      filterBy: (constellation) => constellationsIds.includes(constellation.id),
    });

  extendedConstellations = this.selectAll().pipe(
    map((constellations) =>
      constellations?.map((c) => ({
        ...c,
        nbCapsules:
          capsuleQuery
            .getAll()
            .filter((capsule) =>
              capsule.constellations?.some((constel) => constel.id === c.id)
            ).length || 0,
      }))
    )
  );
}

export const constellationQuery = new ConstellationQuery(constellationStore);

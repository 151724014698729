import React from 'react';

import { Backdrop, CircularProgress, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.background.default,
    backgroundBlendMode: 'lighten',
  },
  circular: { color: theme.palette.background.default },
  text: {
    position: 'absolute',
    bottom: theme.spacing(15),
    marginLeft: '6%',
    marginRight: '6%',
    maxWidth: 600,
  },
}));

export declare interface LoadingScreenViewProps {
  isOpen?: boolean;
  text?: string;
}

const LoadingScreenView = ({
  isOpen = false,
  text = '',
}: LoadingScreenViewProps) => {
  const classes = useStyles();
  return (
    <Backdrop open={isOpen} className={classes.container}>
      <CircularProgress
        disableShrink
        size={120}
        className={classes.circular}
        thickness={1}
      />
      <Typography align="center" className={classes.text} variant="h5">
        {text}
      </Typography>
    </Backdrop>
  );
};

export default LoadingScreenView;

import { ID, QueryEntity } from '@datorama/akita';
import { map, tap } from 'rxjs/operators';

import { capsuleQuery } from '../capsule/capsule.query';
import {
  CapsulePollState,
  CapsulePollStore,
  capsulePollStore,
} from './capsule-poll.store';

export class CapsulePollQuery extends QueryEntity<CapsulePollState> {
  constructor(protected store: CapsulePollStore) {
    super(store);
  }

  getPollByCapsuleId(capsuleId: ID) {
    return this.selectAll({
      filterBy: (entity) => entity.capsuleId === capsuleId,
    }).pipe(
      tap((polls) => console.log('polls', polls)),
      map((polls) => polls[0])
    );
  }

  activeCapsulePolls$ = this.selectAll().pipe(
    // tap(polls => console.log('capsuleSolutions', capsuleQuery.getActiveId(), polls)),
    map((polls) =>
      polls.filter((poll) => poll.capsuleId === capsuleQuery.getActiveId())
    )
  );
}

export const capsulePollQuery = new CapsulePollQuery(capsulePollStore);

/**
 * Truncate a string and add '...' at the end if caracter count exceeds input.
 *
 * @param {string} str
 * @param {number} n
 * @param {boolean} useWordBoundary?
 * @returns
 */
export const truncate = (
  str: string,
  n: number,
  useWordBoundary: boolean = false
): string => {
  if (!str || str.length <= n) {
    return str;
  }
  const subString = str.substr(0, n - 1); // the original check
  return (
    (useWordBoundary
      ? subString.substr(0, subString.lastIndexOf(' '))
      : subString) + String.fromCharCode(8230)
  );
};

export const truncateWord = (str: string, nbLetter: number) =>
  str.substr(str.length - nbLetter, nbLetter);

export const Subscribers = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1_25)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.95073 10.8333C6.66699 11.4827 6.5069 12.1985 6.50022 12.9509L6.5 13V19.5L0 19.5V15.1667C0 12.7964 1.90058 10.8722 4.26165 10.8339L4.33333 10.8333L6.95073 10.8333ZM15.1667 8.66667C17.5599 8.66667 19.5 10.6068 19.5 13V19.5H7.58333V13C7.58333 10.6068 9.52343 8.66667 11.9167 8.66667H15.1667ZM5.41667 3.25C7.2085 3.25 8.66667 4.70817 8.66667 6.5C8.66667 8.29183 7.2085 9.75 5.41667 9.75C3.62483 9.75 2.16667 8.29183 2.16667 6.5C2.16667 4.70817 3.62483 3.25 5.41667 3.25ZM13.5417 0C15.6325 0 17.3333 1.70083 17.3333 3.79167C17.3333 5.8825 15.6325 7.58333 13.5417 7.58333C11.4508 7.58333 9.75 5.8825 9.75 3.79167C9.75 1.70083 11.4508 0 13.5417 0Z"
        fill="#0063C6"
      />
    </g>
    <defs>
      <clipPath id="clip0_1_25">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

import React, { useState } from 'react';

import { makeStyles, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';

import useDeviceDetect from '@hooks/useDeviceDetect';

import BgSvg from '../../../assets/path.svg';
import Logo from '../../../assets/ZIDEEUP.svg';
import PrimaryButton from '../../atoms/Buttons/PrimaryButton.view';
import TextInput from '../../atoms/TextInput/TextInput.view';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.primary.main,
    backgroundImage: `url(${BgSvg})`,
    textAlign: 'center',
    borderRadius: 10,
    padding: theme.spacing(4),
    color: theme.palette.background.default,
  },
  content: { maxWidth: 400 },
  logo: {
    paddingTop: 130,
    paddingBottom: 130,
  },
  link: {
    userSelect: 'none',
    cursor: 'pointer',
    textDecoration: 'underline',
  },
  registerButton: {
    maxWidth: 334,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

export declare interface PasswordResetViewProps {
  onPasswordReset: (password: string) => void;
  isFirstPassword: boolean;
}

const PasswordResetView = ({ onPasswordReset, isFirstPassword }) => {
  const { isMobile } = useDeviceDetect();
  const classes = useStyles();

  const [password, setPassword] = useState('');
  const [verifiedPassword, setVerifiedPassword] = useState('');
  const [isError, setIsError] = useState<boolean>(false);

  return (
    <Grid
      xs={isMobile ? 11 : 10}
      container
      direction="column"
      justifyContent="space-between"
      alignItems="center"
      className={classes.container}
    >
      <Grid item>
        <Grid container direction="column" justifyContent="center" spacing={10}>
          <Grid item className={classes.content}>
            <img className={classes.logo} src={Logo} alt="Logo" />
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="body2">
                  Veuillez choisir votre {isFirstPassword ? '' : 'nouveau'} mot
                  de passe
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TextInput
                  placeholder="Mot de passe"
                  onBlur={(text) => setPassword(text)}
                  type={'password'}
                />
              </Grid>
              <Grid item xs={12}>
                <TextInput
                  onBlur={(text) => setVerifiedPassword(text)}
                  type={'password'}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <div className={classes.registerButton}>
              <PrimaryButton
                onClick={() => {
                  // TODO: Improve this with true password policy.
                  if (verifiedPassword === password) {
                    onPasswordReset(password);
                    setIsError(false);
                  } else {
                    setIsError(true);
                  }
                }}
              >
                Enregistrer
              </PrimaryButton>
            </div>
          </Grid>
          {isError && (
            <Grid item>
              <Typography variant="body2">
                Les mots de passe ne correspondent pas.
              </Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PasswordResetView;

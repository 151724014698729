import * as R from 'ramda';
import { Capsule } from '../capsule.model';

export type CapsuleValidatorPosition =
  Capsule['validators'][0]['user']['position'];

export const getCapsuleDirections = (capsule: Capsule) =>
  capsule.validators &&
  capsule.validators
    ?.map((validator) => validator.user.position)
    .filter(Boolean);

export const getCapsulesDirections = (
  capsules: Capsule[]
): CapsuleValidatorPosition[] =>
  R.uniq(capsules.flatMap(getCapsuleDirections)).filter(Boolean);

import { User } from '../../user/user-permissions.model';
import { Capsule } from '../capsule.model';
import { UserInviteType } from '../user-invite-type.enum';
import { Tag } from '../../tag/tag.model';
import { ID } from '@datorama/akita';

/**
 * Contains people invited to see a capsule which are not part of a constellation which grants access to capsule
 *
 * @export
 * @class CapsuleInvitedUser
 */
export class CapsuleInvitedUser {
  capsuleId: number;
  invitedUserId: number;
  invitedAt: Date;
  invitedById: number;
  inviteType?: UserInviteType;
  capsule: Capsule;
  invitedUser: User;
  invitedBy: User;
}

const userIsTagValidator = (tags: Tag[], userId: ID) =>
  tags?.some((tag) =>
    tag.validators?.some((validator) => validator.userId === userId)
  );

export const isUserInvitedToCapsule = (user: User, capsule: Capsule): boolean =>
  user &&
  capsule.invitedUsers?.map((u) => u.invitedUserId).includes(Number(user.id)) &&
  !userIsTagValidator(capsule.tags, user.id);

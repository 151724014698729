import Grid from '@material-ui/core/Grid';
import Logo from '@components/atoms/Logo/Logo';
import RoundLogo from '@components/atoms/Logo/RoundLogo';
import { hasCompanyLogo } from '../../../utils/env';
import OnBoardingScreen from '@components/screens/LoginScreen/components/OnBoardingScreen';
import React from 'react';
import useIsMobile from '@hooks/useIsMobile/useIsMobile';
import { useStyles } from '@components/screens/LoginScreen/loginScreen.style';
import Typography from '@material-ui/core/Typography';
import { PrimaryButton } from '@components/atoms/Buttons';
import { Icon } from '@material-ui/core';
import { Favorite, Star } from '@material-ui/icons';

const isLandingWaiting = (window as any).env.REACT_APP_LANDING === 'waiting';
const isLandingRedirect = (window as any).env.REACT_APP_LANDING === 'redirect';

export const displayLandingScreen = () => isLandingWaiting || isLandingRedirect;

const getText = () => {
  if (isLandingRedirect)
    return {
      title: (
        <Typography
          variant={'h2'}
          style={{ fontSize: '2.5em', textTransform: 'uppercase' }}
        >
          Nouvelle accès à votre espace
        </Typography>
      ),
      descr: (
        <Typography
          variant={'body1'}
          style={{ fontSize: '1.5em', marginTop: '1em' }}
        >
          Cliquez sur le lien pour vous connecter
        </Typography>
      ),
      btn: (
        <a
          href={'https://mysolution.siege.cepacr.caisse-epargne.fr'}
          target={'_blank'}
        >
          <PrimaryButton style={{ marginTop: '1em' }} color={'secondary'}>
            https://mysolution.siege.cepacr.caisse-epargne.fr
          </PrimaryButton>
        </a>
      ),
      bottom: (
        <Typography
          variant={'body1'}
          style={{ fontSize: '1.5em', marginTop: '1em' }}
        >
          Pensez également à l'enregistrer dans vos favoris{' '}
          <Icon style={{ position: 'relative', top: '5px' }}>
            <Star />
          </Icon>
        </Typography>
      ),
    };
  if (isLandingWaiting)
    return {
      title: (
        <Typography
          variant={'h2'}
          style={{ fontSize: '2.5em', textTransform: 'uppercase' }}
        >
          Interruption temporaire
        </Typography>
      ),
      descr: (
        <Typography
          variant={'body1'}
          style={{ fontSize: '1.5em', marginTop: '1em' }}
        >
          Du vendredi 7 Juillet au
          <br /> lundi 10 Juillet inclus
          <br />
          <br />
          On se retrouve très vite !{' '}
          <Icon style={{ position: 'relative', top: '5px' }}>
            <Favorite />
          </Icon>
        </Typography>
      ),
    };
};

export const WaitingScreen = () => {
  const isMobile = useIsMobile();
  const classes = useStyles({ isMobile });

  const text = getText();

  return (
    <Grid
      xs={isMobile ? 11 : 10}
      container
      direction="row"
      className={classes.parent}
    >
      <Grid
        item
        xs={isMobile ? 12 : 5}
        container
        direction="column"
        justifyContent="space-between"
        alignItems="center"
        className={`${classes.container}`}
      >
        <Grid item style={{ height: '100%' }}>
          <Grid
            container
            direction="column"
            justifyContent="center"
            spacing={10}
            style={{ height: '100%' }}
          >
            <Grid item className={classes.content}>
              {text.title}
              <Logo />
              <RoundLogo hasCompanyLogo={hasCompanyLogo()} />
              {text.descr}
              {text.btn && text.btn}
              {text.bottom && text.bottom}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {!isMobile && (
        <Grid item xs={7} className={classes.signInSlide}>
          <OnBoardingScreen />
        </Grid>
      )}
    </Grid>
  );
};

import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory, useLocation } from 'react-router-dom';

import { ROUTES } from '../../routes/routes';
import { sessionService } from '../../store/session/session.service';

import LoadingScreen from '../../components/screens/LoadingScreen';
import PasswordReset from '../../components/screens/PasswordReset';

const CODE_VERIF_ERROR_MESSAGE =
  'Vous ne pouvez pas utiliser deux fois le même email de réinitialisation.';
const EMAIL_VERIF_SUCCESS_MESSAGE = `Votre mot de passe a été changé. Vous pouvez vous connecter.`;
const FIRST_PASSWORD_SUCCESS_MESSAGE = `Votre mot de passe a été défini. Vous pouvez vous connecter.`;
const GLOBAL_ERROR_MESSAGE = `Une erreur est survenue. Veuillez contacter votre administrateur.`;

export const ResetPasswordPage = () => {
  const history = useHistory();
  const location = useLocation();
  const [isLoading, setLoading] = useState(true);
  const [text, setText] = useState(
    'Nous vérifions votre code de réinitialisation de mot de passe.'
  );
  const [code, setCode] = useState<string>();
  const [isFirstPassword, setIsFirstPassword] = useState<boolean>(false);

  // Retrieves verify code
  useEffect(() => {
    const code = new URLSearchParams(location.search).get('code');
    if (!code) {
      setText(CODE_VERIF_ERROR_MESSAGE);
      history.push({
        pathname: ROUTES.SIGN_IN,
        state: { message: CODE_VERIF_ERROR_MESSAGE },
      });
    } else {
      setCode(code);
      setLoading(false);
    }
  }, [location.search, history.push]);

  // Retrieves set first password
  useEffect(() => {
    const isFirstPassword = new URLSearchParams(location.search).get('f');
    if (isFirstPassword) {
      setIsFirstPassword(true);
    }
  }, [location.search]);

  const onPasswordReset = (password: string) => {
    setLoading(true);
    sessionService.resetPassword({ password, resetToken: code }).subscribe(
      (email: string) => {
        if (email) {
          const message = isFirstPassword
            ? FIRST_PASSWORD_SUCCESS_MESSAGE
            : EMAIL_VERIF_SUCCESS_MESSAGE;
          setText(message);
          history.push({
            pathname: ROUTES.SIGN_IN,
            state: { email, message },
          });
        } else {
          // TODO: Cette page doit permettre de renvoyer un code de vérification au besoin.
        }
      },
      ({ response: { data } }) => {
        if (data.message === 'INVALID_RESET_TOKEN') {
          setText(CODE_VERIF_ERROR_MESSAGE);
          history.push({
            pathname: ROUTES.SIGN_IN,
            state: { message: CODE_VERIF_ERROR_MESSAGE },
          });
        } else {
          setText(GLOBAL_ERROR_MESSAGE);
          history.push({
            pathname: ROUTES.SIGN_IN,
            state: { message: GLOBAL_ERROR_MESSAGE },
          });
        }
      }
    );
  };

  if (isLoading) {
    return <LoadingScreen isOpen={true} text={text} />;
  }

  return (
    <>
      <Helmet>
        <title>Réinitialisation du mot de passe</title>
      </Helmet>
      <PasswordReset
        onPasswordReset={onPasswordReset}
        isFirstPassword={isFirstPassword}
      />
    </>
  );
};

export default ResetPasswordPage;

import { truncate } from '../../../../utils/truncate';
import { capsuleNbItems, getCaptionListItemText } from './ListItemInfo';
import { Capsule } from '../../capsule.model';
import { isAllowedToShare, User } from '../../../user/user-permissions.model';
import { isUserInvitedToCapsule } from '../../capsule-invited-user/capsule-invited-user.model';

export const titleToDisplay = (capsule: Capsule, isMobile: boolean) =>
  truncate(capsule.title, isMobile ? 50 : 80, !isMobile);

export const captionText = (capsule: Capsule, isMobile: boolean) =>
  getCaptionListItemText(capsule, isMobile);

export const getConstellationLabelName = (
  constellationName: string,
  isCapsuleDetailsPage: boolean
) => {
  if (isCapsuleDetailsPage) return constellationName;
  return truncate(constellationName, 20);
};

export const formatCapsulesForList =
  (user: User, isMobile: boolean) =>
  (capsule: Capsule): CapsuleToDisplay => ({
    ...capsule,
    isAllowedToShare: isAllowedToShare(user, capsule),
    metaInfo: capsuleNbItems(capsule, isMobile),
    title: titleToDisplay(capsule, isMobile),
    isUserInvitedToCapsule: isUserInvitedToCapsule(user, capsule),
    captionText: captionText(capsule, isMobile),
  });

export type MetaInfos = { text: JSX.Element | string }[];

export interface CapsuleToDisplay extends Capsule {
  isAllowedToShare: boolean;
  metaInfo: MetaInfos;
  isUserInvitedToCapsule: boolean;
  captionText: string;
}

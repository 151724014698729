import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    padding: '15px 20px 10px 20px',
    margin: '60px 20px 30px 20px',
    borderRadius: '10px',
    textAlign: 'center',
    backgroundColor: 'white',
    maxWidth: '200px',

    '& img': {
      width: '100%',
    },
  },
}));

const Logo = () => {
  const classes = useStyles();
  const logo = (window as any).env.REACT_APP_COMPANY_SIGNATURE_LOGO;
  const appName = (window as any).env.REACT_APP_APP_NAME || 'ZideeUp';

  return (
    <div className={classes.root}>
      {logo ? (
        <img src={'data:image/svg+xml;base64,' + logo} alt={appName} />
      ) : (
        ''
      )}
    </div>
  );
};

export default Logo;

export function userRoleModel(): string {
  return 'domain-user';
}

export type ID = number | string;

export enum UserRole {
  ADMIN = 'ADMIN',
  REFERENT = 'REFERENT',
  EMPLOYE = 'EMPLOYE',
  SUPER_ADMIN = 'SUPER_ADMIN',
}

// User is participating user if one of this condition is true :
// comment : create
// vote : add star to capsule
// poll : create or response
// solution : create or vote
// create capsule
// vote : +10-10
export class CapsuleParticipatingUser {
  participatingUserId: number | undefined;
}

export const isParticipatingUser =
  (userId: ID) =>
  (capsule: { participatingUsers: CapsuleParticipatingUser[] }) =>
    capsule.participatingUsers?.some(
      (pUser) => pUser.participatingUserId === userId
    );
export const isUser = (user?: { id?: ID }) => !!user?.id;
export const isCapsuleCreator =
  (user: { id: ID }) =>
  (capsule: { createdBy: { id: ID } }): boolean =>
    isUser(user) && capsule.createdBy.id === user.id;

export const isAdmin = (user: { role: UserRole }) =>
  user.role === UserRole.ADMIN;

export const isReferentOrAdmin = (user: { id: ID; role: UserRole }) =>
  isUser(user) && [UserRole.ADMIN, UserRole.REFERENT].includes(user.role);

export const isCapsulePilote =
  (user: { id?: ID }) => (capsule: { assignedTo?: { id: ID } }) =>
    isUser(user) && capsule.assignedTo?.id === user?.id;

export const isCapsuleActivator =
  (user: { id?: ID }) => (capsule: { activatedBy?: { id: ID } }) =>
    isUser(user) && capsule.activatedBy?.id === user?.id;

export const isPiloteDifferentCreator = (capsule: {
  createdBy?: { id: ID };
  assignedTo?: { id: ID };
}) => capsule.assignedTo && capsule.createdBy?.id !== capsule.assignedTo?.id;

export const isCapsuleResolver =
  (user: { id?: ID }) => (capsule: { resolvedBy?: { id: ID } }) =>
    isUser(user) && capsule.resolvedBy?.id === user?.id;

export const isCurrentUserExpert = (
  capsuleValidation: { userId: ID },
  user: { id: ID }
): boolean => isUser(user) && capsuleValidation.userId === user.id;

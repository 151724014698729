import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory, useLocation } from 'react-router-dom';

import { ROUTES } from '../../routes/routes';
import { sessionService } from '../../store/session/session.service';
import { SetFirstPassword } from '../../store/session/set-first-password.model';

import LoadingScreen from '../../components/screens/LoadingScreen/';

const EMAIL_VERIF_ERROR_MESSAGE = 'Votre code de vérification est invalide.';
const EMAIL_VERIF_SUCCESS_MESSAGE = `Votre email est vérifié. Vous pouvez vous connecter.`;
const GLOBAL_ERROR_MESSAGE = `Une erreur est survenue. Veuillez contacter votre administrateur.`;

export const VerifyEmailPage = (props: any) => {
  const history = useHistory();
  const location = useLocation();
  const [text, setText] = useState('Nous vérifions votre email');

  // Retrieves verify code
  useEffect(() => {
    const code = new URLSearchParams(location.search).get('code');
    if (!code) {
      setText(EMAIL_VERIF_ERROR_MESSAGE);
      // history.push(ROUTES.SIGN_IN, {state: {error: EMAIL_VERIF_ERROR_MESSAGE}})
    } else {
      const sub = sessionService.verifyEmail(code).subscribe(
        (response: SetFirstPassword) => {
          if (response.code) {
            setText(EMAIL_VERIF_SUCCESS_MESSAGE);
            history.push({
              pathname: ROUTES.RESET_PASSWORD,
              search: `?code=${response.code}&f=1`,
            });
          } else {
            // TODO: Cette page doit permettre de renvoyer un code de vérification au besoin.
          }
        },
        ({ response: { data } }) => {
          if (data.message === 'VERIFICATION_CODE_NOT_FOUND') {
            setText(EMAIL_VERIF_ERROR_MESSAGE);
            history.push({
              pathname: ROUTES.SIGN_IN,
              state: { message: EMAIL_VERIF_ERROR_MESSAGE },
            });
          } else {
            setText(GLOBAL_ERROR_MESSAGE);
            history.push({
              pathname: ROUTES.SIGN_IN,
              state: { message: GLOBAL_ERROR_MESSAGE },
            });
          }
        }
      );
      return () => sub.unsubscribe();
    }

    // history.push(ROUTES.SIGN_IN)
  }, [location.search, history.push]);

  return (
    <>
      <Helmet>
        <title>Vérification de l'email...</title>
      </Helmet>
      <LoadingScreen isOpen={true} text={text} />
    </>
  );
};

export default VerifyEmailPage;

import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';

import { Solution } from './solution.model';

export interface SolutionState extends EntityState<Solution> {
  ui: {
    isUploadingDocument: boolean;
  };
}
export const initialCapsuleState: SolutionState = {
  ui: {
    isUploadingDocument: false,
  },
};
@StoreConfig({
  name: 'solution',
})
export class SolutionStore extends EntityStore<SolutionState> {
  constructor() {
    super(initialCapsuleState);
  }
}

export const solutionStore = new SolutionStore();

import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import { NotificationButtonProps } from './Buttons.types';
import { SecondaryButton } from './Buttons';

const useStyles = makeStyles((theme) => ({
  button: {
    fontWeight: 400,
    color: (props: Partial<NotificationButtonProps>) =>
      props.isSecondary
        ? theme.palette.secondary.main
        : theme.palette.primary.main,
    backgroundColor: 'white',
    borderWidth: 1,
    borderColor: (props: Partial<NotificationButtonProps>) =>
      props.isSecondary
        ? theme.palette.secondary.main
        : theme.palette.primary.main,

    '&:hover': {
      backgroundColor: theme.palette.grey[300],
    },
  },
}));

const NotificationButtonView = ({
  text,
  isSecondary,
  onClick,
}: NotificationButtonProps) => {
  const classes = useStyles({ isSecondary });

  return (
    <SecondaryButton className={classes.button} onClick={onClick}>
      {text}
    </SecondaryButton>
  );
};

export default NotificationButtonView;

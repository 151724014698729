import React from 'react';
import { Helmet } from 'react-helmet';

import { sessionService } from '../../store/session/session.service';

import PasswordResetRequest from '../../components/molecules/PasswordResetRequest/PasswordResetRequest';

export const ForgottenPasswordPage = () => {
  const onPasswordResetRequest = (email: string) => {
    sessionService.forgotPassword(email).subscribe();
  };

  return (
    <>
      <Helmet>
        <title>Mot de passe oublié</title>
      </Helmet>
      <PasswordResetRequest onPasswordResetRequest={onPasswordResetRequest} />
    </>
  );
};

export default ForgottenPasswordPage;

import { ID } from '@datorama/akita';
import { Constellation } from '@store/constellation/constellation.model';
import { ValidatorModel } from '@store/validator/validator.model';
import { categoryQuery } from '../category/category.query';

export class Tag {
  id: ID;
  companyId: number;
  text: string;
  coefficientBonus: number;
  validators?: ValidatorModel[];
  categoryId?: ID;
  path: string;
  archived: boolean;
  constellations?: Constellation[];
}

export const isArchived = (tag: Tag): boolean => tag.archived;
export const isOldVersionTag = (tag: Tag): boolean =>
  categoryQuery.isCategoryEnabled() && !tag.categoryId;

import { capsuleQuery, CapsuleQuery } from '../capsule.query';
import { LabelCriteriaId } from '@zdlibs/domain/label';
import { map } from 'rxjs/operators';
import { take } from 'rxjs';

export class CapsuleLabelQuery {
  constructor(private queryCaspule: CapsuleQuery) {}

  getCapsuleByCriteria(criteriaId: LabelCriteriaId) {
    return this.queryCaspule
      .selectAll({
        filterBy: ({ criteria }) =>
          criteria.map((c) => c.id).includes(criteriaId),
      })
      .pipe(
        map((res) => res),
        take(1)
      );
  }
}

export const capsuleLabelQuery = new CapsuleLabelQuery(capsuleQuery);
